import React from 'react';
import PropTypes from 'prop-types';
import useGetRolesList from 'hooks/useGetRolesList';
import equal from 'deep-equal';
import Select from './Select';

CompanyRoleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  //source: PropTypes.object.isRequired,
  noDefault: PropTypes.bool,
};
CompanyRoleSelect.defaultProps = {
  isClearable: false,
  placeholder: 'Select Role',
  //source: {},
  noDefault: true,
};

function CompanyRoleSelect(props) {
  const {name, defaultValue, noDefault, branchId, ...rest} = props;

  const {isFetching, roles} = useGetRolesList(branchId);

  const _options = roles.map((role) => {
    const {company_role_id, company_role_name, company_role_code} = role;

    return {
      label: company_role_name,
      value: company_role_id,
      code: company_role_code,
    };
  });

  const _defaultValue = _options.find(
    (option) =>
      equal(option.code, defaultValue) || equal(option.value, defaultValue)
  );

  return (
    <>
      <Select
        key={isFetching}
        isLoading={isFetching}
        name={name}
        options={_options}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
        defaultValue={_defaultValue}
        {...rest}
      />
    </>
  );
}

export default CompanyRoleSelect;
