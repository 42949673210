import React from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'lodash/function';
import {Select} from 'components/Selects';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';

MappingSelect.propTypes = {
  action: PropTypes.func.isRequired,
  optionLabel: PropTypes.string.isRequired,
  optionValue: PropTypes.string.isRequired,
};

MappingSelect.defaultProps = {
  isClearable: true,
  filter: {},
  name: 'mapping_target',
  optionLabel: 'field_value',
  optionValue: 'field_value',
  placeholder: 'Search or Select for field name',
};

function MappingSelect(props) {
  const {
    action,
    name,
    defaultValue,
    optionLabel,
    optionValue,
    filter,
    ...rest
  } = props;
  const {alertByError} = useAlert();

  const [fetch, isLoading] = useFetch(action);

  async function loadOptions(value, callback) {
    const query = {
      limit: 100,
      order: 'ASC',
      order_by: 'field_value',
      filter: {
        search: value,
        ...filter,
      },
    };

    try {
      const {data} = await fetch(query);
      return callback(data);
    } catch (e) {
      await alertByError(e);
      return callback([]);
    }
  }

  return (
    <Select
      async
      isLoading={isLoading}
      name={name}
      getOptionLabel={(option) => option[optionLabel]}
      getOptionValue={(option) => option[optionValue]}
      defaultValue={defaultValue}
      loadOptions={debounce(loadOptions, 250)}
      {...rest}
    />
  );
}

export default MappingSelect;
