import {useEffect, useState, useCallback, useRef} from 'react';
import {merge} from 'lodash/object';

const defaultOptions = {
  initialLoading: false,
  response: {},
  timeout: 300,
};

function useFetch(action, options) {
  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true; // componentDidMount
    return () => {
      isMounted.current = false; // componentWillUnmount
    };
  });

  const {initialLoading, timeout} = merge({}, defaultOptions, options);

  const [state, setState] = useState({
    isLoading: initialLoading,
    response: {},
  });

  const fetchSuccess = useCallback(
    async (response) => {
      if (timeout && timeout > 0) {
        await new Promise((resolve) => setTimeout(resolve, timeout));
      }

      if (isMounted.current) {
        setState({response, isLoading: false});
      }

      return Promise.resolve(response);
    },
    [timeout]
  );

  const fetchFailure = useCallback(async (error) => {
    if (isMounted.current) {
      setState((current) => ({...current, isLoading: false}));
    }

    return Promise.reject(error);
  }, []);

  const fetch = useCallback(
    async (...args) => {
      if (isMounted.current) {
        setState((current) => ({...current, isLoading: true}));
      }

      return await action(...args).then(fetchSuccess, fetchFailure);
    },
    [action, fetchSuccess, fetchFailure]
  );

  const {isLoading, response} = state;
  return [fetch, isLoading, response];
}

export default useFetch;
