import React, {useEffect, useContext, useState} from 'react';
import {useLocation} from 'react-router-dom';
import useAlert from 'hooks/useAlert';
import {
  getAccountNotifications,
  updateAccountNotification,
  updateAccountNotificationMarkAllRead,
  deleteAccountNotification,
  updateAccountNotificationTurnOff,
  getAccountNotificationCount,
} from 'api/accounts';
import {AuthContext} from 'contexts/AuthContext';
import {useMaintenance} from 'contexts/MaintenanceContext';

const NotificationContext = React.createContext({});

function NotificationProvider({children}) {
  const {pathname} = useLocation();

  const {isMaintenance} = useMaintenance();
  const {isAuth} = useContext(AuthContext);
  const {alertByError} = useAlert();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [nextKey, setNextKey] = useState('');
  const [type, setType] = useState('');
  const [title, setTitle] = useState('Notifications');

  const [isUpdatesOpen, setIsUpdatesOpen] = useState(false);
  const [isEnquiriesOpen, setIsEnquiriesOpen] = useState(false);

  useEffect(() => {
    getNotificationCount();

    return () => {
      setCount(0);
      setData([]);
    };
  }, [pathname]);

  async function getNotificationCount() {
    if (!isAuth || isMaintenance) return false;

    const {data} = await getAccountNotificationCount();

    setCount(data || 0);
  }

  function toggleOpen(notifType) {
    if (isOpen) {
      setIsOpen(!isOpen);
      setTitle('');
    } else {
      setTitle(notifType === 'update' ? 'Notifications' : 'Enquiries');
      setIsOpen(true);
      getNotification(false, notifType);
    }
  }

  function toggleOpenEnquiries(notifType) {
    if (isEnquiriesOpen) {
      setIsEnquiriesOpen(!isEnquiriesOpen);
      setTitle('');
    } else {
      setTitle(notifType === 'update' ? 'Notifications' : 'Enquiries');
      setIsEnquiriesOpen(true);
      getNotification(false, notifType);
    }
  }

  function toggleOpenUpdates(notifType) {
    if (isUpdatesOpen) {
      setIsUpdatesOpen(!isUpdatesOpen);
      setTitle('');
    } else {
      setTitle(notifType === 'update' ? 'Notifications' : 'Enquiries');
      setIsUpdatesOpen(true);
      getNotification(false, notifType);
    }
  }

  async function getNotification(onSettings, notifType) {
    setIsLoading(true);
    try {
      const {data: dataResponse, meta} = onSettings
        ? await getAccountNotifications({
            key: nextKey,
          })
        : await getAccountNotifications({
            type: notifType,
          });

      const {next_key} = meta;

      if (onSettings) {
        const {next_key} = meta;
        const newData = [...data];
        newData.push(...dataResponse);
        setData(newData);
        setNextKey(next_key);
      } else {
        setData(dataResponse);
        setNextKey(next_key);
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      alertByError(e);
    }
  }

  async function handleMarkAllAsRead(notifType) {
    try {
      await updateAccountNotificationMarkAllRead(type);
      getNotification(false, notifType);
      await getNotificationCount();
    } catch (e) {
      alertByError(e);
    }
  }

  async function handleMarkAsRead(index, item) {
    const {notification_id} = item;

    try {
      const newData = [...data];
      item.status = 'read';
      newData[index] = item;
      setData(newData);

      await updateAccountNotification({
        notification_id,
        status: 'read',
      });
      await getNotificationCount();
    } catch (e) {
      alertByError(e);
    }
  }

  async function handleRemoveNotification(id) {
    try {
      const newData = data.filter((item) => item.notification_id !== id);
      setData(newData);

      await deleteAccountNotification(id);
    } catch (e) {
      alertByError(e);
    }
  }

  async function handleTurnOffNotification(item) {
    try {
      await updateAccountNotificationTurnOff(item);

      getNotification();
    } catch (e) {
      alertByError(e);
    }
  }

  async function handleClick(status, notificationId, link) {
    if (!isAuth) return false;
    // setIsOpen(false);
    if (isUpdatesOpen) {
      setIsUpdatesOpen(false);
    } else {
      setIsEnquiriesOpen(false);
    }

    if (status === 'unread') {
      await updateAccountNotification({
        notification_id: notificationId,
        status: 'read',
      });
    }

    await getNotificationCount();
  }

  return (
    <NotificationContext.Provider
      value={{
        getNotification,
        isOpen,
        toggleOpen,
        data,
        count,
        nextKey,
        isLoading,
        title,
        type,
        isEnquiriesOpen,
        isUpdatesOpen,
        toggleOpenUpdates,
        toggleOpenEnquiries,
        setType,
        handleClick,
        handleMarkAsRead,
        handleRemoveNotification,
        handleTurnOffNotification,
        handleMarkAll: handleMarkAllAsRead,
        getNotificationCount,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

function useNotification() {
  const states = useContext(NotificationContext);

  return states;
}

export {NotificationContext, NotificationProvider, useNotification};
