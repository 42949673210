import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash/object';
import {StandardSelect} from 'components/Selects';

ObjectStandardSelect.propTypes = {
  async: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  defaultOptions: PropTypes.bool,
  isClearable: PropTypes.bool,
  action: PropTypes.func.isRequired,
};

ObjectStandardSelect.defaultProps = {
  async: true,
  cacheOptions: true,
  defaultOptions: true,
  isClearable: true,
  placeholder: 'Search / Select standard',
};

function ObjectStandardSelect(props) {
  const {name, standards_id, standards_name, onChange, ...rest} = props;

  const defaultValue = standards_id &&
    standards_name && {standards_id, standards_name};

  const [selected, setSelected] = useState(defaultValue);

  function handleChange(selected) {
    const {standards_id, standards_name} = selected || {};
    setSelected(
      standards_id && standards_name ? {standards_id, standards_name} : ''
    );

    if (onChange) onChange(selected);
  }

  return (
    <>
      <input
        type="hidden"
        name="standards_id"
        value={get(selected, 'standards_id', '')}
      />

      <input
        type="hidden"
        name="standards_name"
        value={get(selected, 'standards_name', '')}
      />

      <StandardSelect
        value={selected || ''}
        onChange={handleChange}
        getOptionLabel={(option) => option['standards_name']}
        getOptionValue={(option) => ({
          standards_id: option.standards_id,
          standards_name: option.standards_name,
        })}
        {...rest}
      />
    </>
  );
}

export default ObjectStandardSelect;
