import React from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'lodash/function';
import cleanDeep from 'clean-deep';

import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';
import Select from './Select';

SelectAsync.propTypes = {
  action: PropTypes.func.isRequired,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  filter: PropTypes.object,
  query: PropTypes.object,
};

SelectAsync.defaultProps = {
  isClearable: true,
};

function SelectAsync(props) {
  const {
    action,
    name,
    defaultValue,
    value,
    optionLabel,
    optionValue,
    query,
    filter,
    ...rest
  } = props;

  const [fetch, isLoading] = useFetch(action);
  const {alertByError} = useAlert();

  async function loadOptions(value, callback) {
    const _query = cleanDeep({
      limit: 100,
      filter: {
        search: value,
        ...filter,
      },
      ...query,
    });

    try {
      const {data} = await fetch(_query);
      return callback(data);
    } catch (e) {
      await alertByError(e);
      return callback([]);
    }
  }

  return (
    <Select
      async
      isLoading={isLoading}
      name={name}
      getOptionLabel={(option) => option[optionLabel]}
      getOptionValue={(option) => option[optionValue]}
      defaultValue={defaultValue}
      value={value}
      loadOptions={debounce(loadOptions, 250)}
      {...rest}
    />
  );
}

export default SelectAsync;
