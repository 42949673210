import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'lodash/function';
import {Select} from 'components/Selects';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';
import {isEmpty} from 'lodash';

StandardSelect.propTypes = {
  action: PropTypes.func.isRequired,
  optionLabel: PropTypes.string.isRequired,
  optionValue: PropTypes.string.isRequired,
};

StandardSelect.defaultProps = {
  isClearable: true,
  //name: 'standards_id', // standards[]  // Remove default props
  optionValue: 'standards_id',
  optionLabel: 'standards_name',
  placeholder: 'i.e. ISO 9001:2018',
};

function StandardSelect(props) {
  const {
    action,
    name,
    defaultValue,
    optionLabel,
    optionValue,
    filter,
    optionFilter,
    cbId,
    ...rest
  } = props;
  const [fetch, isLoading] = useFetch(action);
  const {alertByError} = useAlert();

  const [value, setValue] = useState(defaultValue);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const v = options.find((option) => {
      return option.standards_id === defaultValue;
    });
    setValue(v);

    return () => {
      setValue({}); // prevents memory leak
    };
  }, [defaultValue, options]);

  async function loadOptions(value, callback) {
    const {certification_bodies_ids} = filter || {};

    const query = {
      limit: 100,
      order: 'ASC',
      order_by: 'standards_name',
      filter: {
        search: value,
        certification_bodies_ids,
        ...filter,
      },
    };

    try {
      const {data} = await fetch(query, cbId);

      if (optionFilter && !isEmpty(data)) {
        const _data = optionFilter(data);
        setOptions(_data);
        return callback(_data);
      }

      setOptions(data);
      return callback(data);
    } catch (e) {
      await alertByError(e);
      return callback([]);
    }
  }

  return (
    <Select
      async
      isLoading={isLoading}
      name={name}
      getOptionLabel={(option) => option[optionLabel]}
      getOptionValue={(option) => option[optionValue]}
      value={value}
      defaultValue={defaultValue}
      onChange={(data) => {
        setValue(data);
      }}
      loadOptions={debounce(loadOptions, 250)}
      noOptionsMessage={({inputValue}) =>
        inputValue
          ? `There is no result for '${inputValue}'.'`
          : 'Search and select the correct standard including version.'
      }
      {...rest}
    />
  );
}

export default StandardSelect;
