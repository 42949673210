import {useState, useEffect} from 'react';
import {orderBy} from 'lodash/collection';
import {isEmpty} from 'lodash/lang';
import {getPublicCountries} from 'api/public';
import Stores from 'utils/stores';
import useAlert from './useAlert';

function useCountries() {
  const storedData = Stores.countries || [];

  const {alertByError} = useAlert();
  const [state, setState] = useState({
    isLoading: isEmpty(storedData), // Start as loading, if localstorage is empty
    data: storedData,
  });

  const {isLoading, data} = state;

  useEffect(() => {
    function callbackSuccess({data}) {
      Stores.countries = data; // Set localstorage
      setState({data, isLoading: false});
    }

    async function callbackFailure(e) {
      await alertByError(e);
    }

    async function fetch() {
      return await getPublicCountries();
    }

    if (isEmpty(data)) {
      fetch().then(callbackSuccess, callbackFailure);
    } else {
      setState((current) => ({...current, isLoading: false}));
    }
  }, [data]);

  const arrCountries = [...data];
  let objCountries = {};
  let objSelectCountries = {};

  arrCountries.forEach((item) => {
    const {countries_id, name} = item;
    objCountries[countries_id] = item;
    objSelectCountries[countries_id] = name;
  });

  function parseSelectCountries(_countries) {
    if (!isEmpty(_countries)) {
      // Get UK and remove UK from the list
      const UK = _countries.find((country) => country.code === 'GB');
      const result = _countries.filter((country) => country.code !== 'GB');

      // push UK to the start
      result.unshift(UK);

      return result;
    } else {
      return [];
    }
  }

  return {
    isLoading,
    objCountries,
    objSelectCountries,
    countries: orderBy(arrCountries, ['name'], 'asc'),
    selectCountries: parseSelectCountries(arrCountries),
  };
}

export default useCountries;
