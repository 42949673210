import DASHBOARD from './routes.dashboard';

// accessRule: Only using with the top index in routes configs.
export const ROUTE_ACCESS_RULE = {
  public: 'public', // Open to everyone
  private: 'private', // Logged in user only
  guest: 'guest', // Opposite of private. Non-logged in user only
};

const LOG_IN = {
  INDEX: '/login',
  DEFAULT: '/login',
  COMPANY: '/login/:companyId',
};

const PASSWORD = {
  INDEX: '/password',
  FORGOT: '/password/forgot',
  RESET: '/password/reset/:token',
};

const SIGN_UP = {
  INDEX: '/register',
  TYPES: '/register',
  USER: '/register/user',
};

const DEMO = {
  INDEX: '/demo',
  CAPTCHA: '/demo/captcha',
};

export const ROUTE_PATH = {
  HOME: '/',
  COMING: '/coming',
  CONTACT: '/contact/:type?',
  ABOUT_US: '/about-us',
  ERROR: '/error/:code(404|403|500|session)',
  CERTIFICATION: '/certification/:certId',
  CERT_NOT_FOUND: '/cert-not-found',
  CERTIFIED_ENTITY: '/certified-entity/:ceId',
  CERTIFICATION_BODY: '/certification-body/:cbId',
  CB_SEARCH: '/certification-body',
  MAINTENANCE: '/maintenance',
  LOG_OUT: '/logout',
  LOG_IN,
  SIGN_UP,
  DASHBOARD,
  PASSWORD,
  DEMO,
};
