import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash/object';
import {CESelect} from 'components/Selects';

ObjectCESelect.propTypes = {
  async: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  defaultOptions: PropTypes.bool,
  isClearable: PropTypes.bool,
  action: PropTypes.func.isRequired,
};

ObjectCESelect.defaultProps = {
  async: true,
  cacheOptions: true,
  defaultOptions: true,
  isClearable: true,
  placeholder: 'Search / Select CE',
};

function ObjectCESelect(props) {
  const {company_id, company_name, filter, ...rest} = props;

  const defaultValue = company_id && company_name && {company_id, company_name};

  const [selected, setSelected] = useState(defaultValue);

  function handleChange(selected) {
    const {company_id, company_name} = selected || {};
    setSelected(company_id && company_name ? {company_id, company_name} : '');
  }

  return (
    <>
      <input
        type="hidden"
        name="company_id"
        value={get(selected, 'company_id', '')}
      />

      <input
        type="hidden"
        name="company_name"
        value={get(selected, 'company_name', '')}
      />

      <CESelect
        value={selected || ''}
        onChange={handleChange}
        getOptionLabel={(option) => option['company_name']}
        getOptionValue={(option) => ({
          company_id: option.company_id,
          company_name: option.company_name,
        })}
        {...rest}
      />
    </>
  );
}

export default ObjectCESelect;
