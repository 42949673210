import React from 'react';
import equal from 'deep-equal';

function withObjectSource(Component) {
  return function Enhancer(props) {
    const {name, defaultValue, source, ...rest} = props;

    const _options = Object.keys(source).map((key) => ({
      label: source[key],
      value: key,
    }));

    const _defaultValue = _options.find((option) =>
      equal(option.value, defaultValue)
    );

    return (
      <Component
        name={name}
        options={_options}
        defaultValue={_defaultValue}
        {...rest}
      />
    );
  };
}

export default withObjectSource;
