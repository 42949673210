import React, {useContext} from 'react';
import useGetMaintenanceStatus from 'hooks/useGetMaintenanceStatus';
import {PageLoading} from 'components/Loadings';

const MaintenanceContext = React.createContext({});

function MaintenanceProvider({children}) {
  const {isLoading, isMaintenance} = useGetMaintenanceStatus();

  return (
    <MaintenanceContext.Provider value={{isLoading, isMaintenance}}>
      {isLoading ? <PageLoading isLoading={isLoading} /> : children}
    </MaintenanceContext.Provider>
  );
}

const useMaintenance = () => {
  const state = useContext(MaintenanceContext);

  return state;
};

export {MaintenanceProvider, MaintenanceContext, useMaintenance};
