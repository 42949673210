import React from 'react';
import useQuery from 'hooks/useQuery';
import {Pagination} from 'components/Paginations';

function SearchFilterPagination(props) {
  const {meta, className} = props;
  const {total_pages} = meta || {};

  const {query, buildQueryURL, updateQuery} = useQuery();
  const {offset} = query;

  function hrefBuilder(page) {
    const _offset = page - 1;
    const _query = {offset: _offset > 0 ? _offset : ''};
    return buildQueryURL(_query, false);
  }

  function handleChange({selected}) {
    const _query = {offset: selected > 0 ? selected : ''};
    updateQuery(_query, false);
  }

  return (
    <Pagination
      total={total_pages}
      offset={offset}
      hrefBuilder={hrefBuilder}
      handleChange={handleChange}
      className={className}
    />
  );
}

export default SearchFilterPagination;
