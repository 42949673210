import {keys, pick} from 'lodash/object';
import urlJoin from 'url-join';

import ERROR_MESSAGES from './ErrorMessages';
import {UKAS_SUPPORT_EMAIL} from 'constants/environment';
import {REGEX_FEEDBACK, REGEX_PATTERN} from 'constants/regex';

export {CONSENT_VERSIONS} from 'constants/environment';
export {ERROR_MESSAGES};

//
// ENV
export const COOKIE_PREFIX = process.env.REACT_APP_COOKIE_PREFIX;

//
// KEY
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const HCAPTCHA_SITEKEY = process.env.REACT_APP_HCAPTCHA_SITEKEY;

//
// URL

export const NGROK_API_URL = process.env.REACT_APP_NGROK_API_URL;
export const API_URL = process.env.REACT_APP_API_URL;

//
// Instructions
export const INSTRUCTIONS_URL = 'https://developer.iafcertsearch.org';
export const INSTRUCTION_FILE_UPLOAD = urlJoin(INSTRUCTIONS_URL, 'file-upload');
export const INSTRUCTION_DATA_MAPPING = urlJoin(
  INSTRUCTIONS_URL,
  'file-upload'
);

export const EMAIL_SUPPORT = UKAS_SUPPORT_EMAIL;

const HOST = window.location.protocol + window.location.host;

export const COMPANY_ACTIVATE_URL = urlJoin(HOST, '/act');

//
// STATUS_TYPES
const STATUS_TYPES = {
  active: 'active',
  inactive: 'inactive',
  pending: 'pending',
  hidden: 'hidden',
  cancelled: 'cancelled',
  withdrawn: 'withdrawn',
  cbwithdrawn: 'cbwithdrawn',
  suspended: 'suspended',
  deleted: 'deleted',
  open: 'open',
  closed: 'closed',
  error: 'error',
  success: 'success',
};

export const STATUS_LABELS = {
  [STATUS_TYPES.active]: 'Active',
  [STATUS_TYPES.inactive]: 'Inactive',
  [STATUS_TYPES.pending]: 'Pending',
  [STATUS_TYPES.hidden]: 'Draft', // 'hidden' status is displayed as 'draft'
  [STATUS_TYPES.withdrawn]: 'Withdrawn',
  [STATUS_TYPES.cbwithdrawn]: 'CB Withdrawn',
  [STATUS_TYPES.cancelled]: 'Cancelled',
  [STATUS_TYPES.suspended]: 'Suspended',
  [STATUS_TYPES.deleted]: 'Deleted',
  [STATUS_TYPES.open]: 'Open',
  [STATUS_TYPES.closed]: 'Resolved',
  [STATUS_TYPES.error]: 'Error',
  [STATUS_TYPES.success]: 'Success',
};

export const ACCOUNT_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.inactive,
    STATUS_TYPES.pending,
    STATUS_TYPES.suspended,
  ]),
  ACCOUNT_STATUS_OPTIONS = pick(STATUS_LABELS, keys(ACCOUNT_STATUSES)),
  CERTIFICATION_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.inactive,
    STATUS_TYPES.suspended,
    STATUS_TYPES.cancelled,
    STATUS_TYPES.hidden,
    STATUS_TYPES.withdrawn,
  ]),
  CERTIFICATION_STATUS_OPTIONS = pick(
    STATUS_LABELS,
    keys(CERTIFICATION_STATUSES)
  ),
  BADGE_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.inactive,
    STATUS_TYPES.suspended,
  ]),
  BADGE_STATUS_OPTIONS = pick(STATUS_LABELS, keys(BADGE_STATUSES)),
  STATS_STATUSES = pick(STATUS_TYPES, [
    STATUS_TYPES.active,
    STATUS_TYPES.inactive,
  ]),
  STATS_STATUS_OPTIONS = pick(STATUS_LABELS, keys(STATS_STATUSES));

export const COMPANY_TYPES = {
  ab: 'ab',
  cb: 'cb',
  ce: 'ce',
  company: 'company',
  mncb: 'mncb',
};

//
// SIZES
export const SIZES = {
  origin: 'origin',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

export const VIEWPORT_WIDTHS = {
  [SIZES.xs]: 0,
  [SIZES.sm]: 576,
  [SIZES.md]: 768,
  [SIZES.lg]: 992,
  [SIZES.xl]: 1200,
};

export const IMAGE_WIDTHS = {
  [SIZES.sm]: 160,
  [SIZES.md]: 320,
  [SIZES.lg]: 640,
  [SIZES.origin]: SIZES.origin,
};

export const IMAGE_SUCCESS =
  'https://img.certcheck.ukas.com/assets/images/illustrations/success.svg';
//
// ACCOUNT
export const ACCOUNT_ROLES = {
    super_admin: 'super_admin',
    admin: 'admin',
    manager: 'manager',
    staff: 'staff',
    user: 'user',
  },
  ACCOUNT_ROLE_OPTIONS = {
    [ACCOUNT_ROLES.super_admin]: 'Super Admin',
    [ACCOUNT_ROLES.admin]: 'Admin',
    [ACCOUNT_ROLES.manager]: 'Manager',
    [ACCOUNT_ROLES.staff]: 'Staff',
    [ACCOUNT_ROLES.user]: 'UKAS User',
  },
  ACCOUNT_ROLE_LEVEL = {
    [ACCOUNT_ROLES.super_admin]: 100,
    [ACCOUNT_ROLES.admin]: 50,
    [ACCOUNT_ROLES.manager]: 50,
    [ACCOUNT_ROLES.staff]: 10,
    [ACCOUNT_ROLES.user]: 1,
  };

//
// COMPANY

export const IS_DEFAULT = {
  1: true,
  0: false,
  false: false,
};

export const COMPANY_TYPE_LEVEL = {
  [COMPANY_TYPES.ab]: 100,
  [COMPANY_TYPES.mncb]: 55,
  [COMPANY_TYPES.cb]: 50,
  [COMPANY_TYPES.ce]: 10,
  [COMPANY_TYPES.company]: 1,
};

// Certification Type
export const CERT_TYPE = {
  'Management System': 'Management System',
  // 'Product Certification': 'Product Certification',
};

//
// SECURITY_TYPE
export const SECURITY_TYPE = {
  sign_in: 'Log in',
  sign_out: 'Log out',
  sign_up: 'Sign up',
  activation: 'Activate account',
  change_password: 'Change password',
  update_profile: 'Update profile',
  add_office_directory: 'Add office directory',
  update_office_directory: 'Update office directory',
  update_user_settings: 'Update user settings',
  delete_account: 'Delete account',
  update_security_settings: 'Update security setting',
  update_notification_settings: 'Update notification setting',
  delete_certification: 'Delete certification',
  delete_all_certifications: 'Delete all certifications',
  delete_all_ce_certifications: 'Delete all certifications under',
};

//
// REGEXP
export const REGEXP = REGEX_PATTERN;

//
// REGEXP_FEEDBACK
export const REGEXP_FEEDBACK = REGEX_FEEDBACK;

// ------------
// ROUTE_ACCESS_RULES
// ------------
//
// Only Set Rules at the Top Level

export const ROUTE_ACCESS_RULES = {
  // Open to everyone
  public: 'public',

  // Logged in user only
  private: 'private',

  // Non-logged in user only
  guest: 'guest',
};

export const ROUTER_SCROLL_TYPES = {
  stay: 'stay',
};

//
// ERRORS
export const ERROR_STATUS = {
  400: '400', // 400 Bad Request
  401: '401', //  401 Unauthorized
  403: '403', //  403 Forbidden, The client does not have access rights
  404: '404', //  404 Not found
  500: '500', //  500 Internal Server Error
  503: '503',
  session: 'session', //  session expired
  504: '504',
};
