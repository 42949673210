import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import withObjectSource from './withObjectSource';

StatusSelect.propTypes = {
  name: PropTypes.string,
  source: PropTypes.object.isRequired,
};

StatusSelect.defaultProps = {
  isClearable: false,
  placeholder: 'Select status',
  name: 'status',
  source: {}, // Status source from 'constants/status'
};

function StatusSelect(props) {
  const {name} = props;
  return <Select name={name} {...props} />;
}

export default withObjectSource(StatusSelect);
