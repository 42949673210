import React, {createContext, useState, useContext, useEffect} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {isEmpty} from 'lodash/lang';
import Cookies from 'utils/cookies';
import useSessionAccount from 'hooks/useSessionAccount';
import {get} from 'lodash';

import {PageLoading} from 'components/Loadings';

export const AuthContext = createContext({});

export function validateConsents() {}

// Provider
function AuthProvider(props) {
  const {children} = props;
  let match = useRouteMatch('/login/session/:sessionId');
  const {sessionId} = (match || {}).params || {};

  const [session, setSession] = useState(Cookies.session || null);
  const [authAccount, setAuthAccount] = useState({});

  const isAuth = session && !isEmpty(session);

  const {isLoading, data, getSessionAccount} = useSessionAccount(isAuth);

  useEffect(() => {
    handleSession();
  }, [session]);

  useEffect(() => {
    if (!isLoading) {
      setAuthAccount(data);
    }
  }, [isLoading]);

  async function handleSession() {
    if (sessionId || session) {
      await getSessionAccount(sessionId || session);
    }

    if (sessionId) {
      setAuth({session_id: sessionId});
    }
  }

  function setAuth(data, remember) {
    const {session_id} = data;

    let obj = {
      session: session_id,
    };

    if (remember) {
      obj.remember = remember;
    }

    Cookies.session = obj;

    setSession(session_id);
  }

  function removeAuth() {
    Cookies.session = '';
    setSession(null);
  }

  function updateAuthAccount(data) {
    const {account: dataAccount} = data;
    setAuthAccount((prevState) => {
      const {account, ...rest} = prevState;
      return {
        account: {...account, ...dataAccount},
        ...rest,
      };
    });
  }

  function updateAuthConsent(data) {
    setAuthAccount((prevState) => {
      const {consent, ...rest} = prevState;
      return {
        consent: {...consent, ...data},
        ...rest,
      };
    });
  }

  const {account, consent, account_permission, deleting_all_certifications} =
    authAccount || {};
  const {company} = account || {};
  const {company_type} = company || {};

  const {entity_acronym, entity_name = ''} = company || {};

  const acronym = entity_acronym
    ? entity_acronym
    : (entity_name || '').slice(0, 3);

  function checkPermission(key) {
    return get(account_permission, key);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        setAuth,
        removeAuth,

        account,
        getAccount: () => getSessionAccount(session),
        updateAccount: updateAuthAccount,

        company,
        companyType: company_type,
        acronym: acronym,

        consent,
        updateConsent: updateAuthConsent,

        permission: account_permission,
        checkPermission,

        deleting_all_certifications,
      }}
    >
      {isAuth ? (
        isLoading || isEmpty(account) ? (
          <PageLoading isLoading />
        ) : (
          children
        )
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

// Consumer
const AuthConsumer = AuthContext.Consumer;

// Hook
const useAuth = () => {
  const {
    isAuth,
    setAuth,
    removeAuth,
    account,
    getAccount,
    updateAccount,
    company,
    companyType,
    acronym,
    consent,
    updateConsent,
    permission,
    checkPermission,
  } = useContext(AuthContext);

  return {
    isAuth,
    setAuth,
    removeAuth,
    account,
    getAccount,
    updateAccount,
    company,
    companyType,
    acronym,
    consent,
    updateConsent,
    permission,
    checkPermission,
  };
};

export function withAuth(Component) {
  return function (props) {
    const auth = useContext(AuthContext);

    return <Component auth={auth} {...props} />;
  };
}

export {AuthProvider, AuthConsumer, useAuth};
