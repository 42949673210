export const COMPANY_TYPE = {
  ab: 'ab',
  cb: 'cb',
  ce: 'ce',
  company: 'company',
  mncb: 'mncb',
};

export const COMPANY_TYPE_LABEL = {
  ab: 'Accreditation Body',
  cb: 'Certification Body',
  ce: 'Certified Entity',
  company: 'Company',
  mncb: 'Multi-National Certification Body',
};

export const SCHEME_TYPE = {
  'Management System': 'Management System',
  // 'Product Certification': 'Product Certification',
  // 'Certification of Person': 'Certification of Person',
  // 'Validation and Verification': 'Validation and Verification',
};

export const OFFICE_TYPE = {
  'Corporate Head Office': 'Corporate Head Office',
  'Regional Head Office': 'Regional Head Office',
  'Country Head Office': 'Country Head Office',
  'Branch Office': 'Branch Office',
};

export const ENQUIRY_TYPE = {
  account: 'Account',
  accreditationConfirmation: 'Accreditation Confirmation',
  certificateConfirmation: 'Certificate Confirmation',
  counterfeitVerification: 'Counterfeit Verification',
  requestQuotation: 'Request Quotation',
  feedback: 'Feedback',
  general: 'General',
  sales: 'Sales',
  support: 'Technical Support',
  updateOfficeDirectory: 'Update Office Directory',
  deleteOfficeDirectory: 'Delete Office Directory',
};

export const ENQUIRY_TYPE_COLOR = {
  account: 'primary',
  accreditationConfirmation: 'warning',
  certificateConfirmation: 'warning',
  requestQuotation: 'success',
  feedback: 'success',
  general: 'secondary',
  sales: 'light',
  support: 'light',
  updateOfficeDirectory: 'secondary',
  deleteOfficeDirectory: 'primary',
};

export const ENQUIRY_TYPE_CB = {
  certificateConfirmation: 'Certificate Confirmation',
  updateOfficeDirectory: 'Update Office Directory',
  deleteOfficeDirectory: 'Delete Office Directory',
  feedback: 'Feedback',
  // requestQuotation: 'Request Quotation',
  // feedback: 'Feedback',
  // support: 'Support',
};

export const ENQUIRY_TYPE_AB = {
  // account: 'Account',
  // certificateVerification: 'Certificate Verification',
  // counterfeitVerification: 'Counterfeit Verification',
  // general: 'General',
  // sales: 'Sales',
  updateOfficeDirectory: 'Update Office Directory',
  deleteOfficeDirectory: 'Delete Office Directory',
};

export const ENQUIRY_TYPE_COMPANY = {
  // account: 'Account',
  // accreditationConfirmation: 'Accreditation Confirmation',
  // general: 'General',
  // support: 'Support',
  certificateConfirmation: 'Certificate Confirmation',
  feedback: 'Feedback',
};

export const CERT_GROWTH_TYPE = {
  gains: 'Gains',
  renewed: 'Renewed',
  losses: 'Losses',
};

export const CERT_GROWTH_TYPE_COLOR = {
  gains: '#0ACF97',
  losses: '#ED4C78',
  renewed: '#FFA96A',
};

export const ACCREDITATION_TYPE = {
  'Management System': 'Total MS Certifications',
  'Product Certification': 'Total MS Certifications',
};

export const ACCREDITATION_TYPE_COLOR = {
  'Management System': '#330074',
  'Product Certification': '#2AD2C9',
};

export const USER_TYPE = {
  cb: 'Certification Bodies',
  resuni: 'Research/Universities',
  govreg: 'Government Regulators',
  pub: 'Public',
  oth: 'Others',
  certorg: 'Certificate Organizations',
  // new types
  afsa: 'Accommodation and Food Service Activities',
  assa: 'Administrative and Support Service Activities',
  aff: 'Agriculture, Forestry and Fishing',
  aer: 'Arts, Entertainment and Recreation',
  constr: 'Construction',
  educ: 'Education',
  egsacs: 'Electricity, Gas, Steam and Air Conditioning Supply',
  fia: 'Financial and Insurance Activities',
  hhswa: 'Human Health and Social Work Activities',
  itcom: 'Information and Communication',
  mfg: 'Manufacturing',
  minqua: 'Mining and Quarrying',
  othsa: 'Other Service Activities',
  psta: 'Professional, Scientific and Technical Activities',
  padcs: 'Public Administration and Defence; Compulsory Social Security',
  rea: 'Real Estate Activities',
  transstg: 'Transportation and Storage',
  wsswmra:
    'Water Supply; Sewerage, Waste Management and Remediation Activities',
  wrtrmvm:
    'Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles',
};

export const USER_TYPE_COLOR = {
  cb: '#7d53f4',
  resuni: '#ED4C78',
  govreg: '#FFA96A',
  pub: '#6009CF',
  oth: '#B0B9BF',
  certorg: '#FFE18B',
  // new sectors
  aff: '#330074',
  minqua: '#39AFD1',
  mfg: '#2AD2C9',
  egsacs: '#0ACF97',
  wsswmra: '#B78C4C',
  constr: '#FA816E',
  wrtrmvm: '#B60000',
  transstg: '#FFF0C5', // '80' = 50% opacity
  afsa: '#FFD4B5',
  itcom: '#FFD4B5',
  fia: '#D7DCDF',
  rea: '#9980B9',
  psta: '#9CD7E8',
  assa: '#94E8E4',
  padcs: '#84E7CB',
  educ: '#DBC6A6',
  hhswa: '#FCC0B7',
  aer: '#DA8080',
  othsa: '#1E2022',
};

// Market Trend page filter
export const ANALYTICS_USERS_TYPE = {
  status: 'Users by status',
  organization: 'Users by organization',
};

export const ANALYTICS_PAGEVIEWS_TYPE = {
  ce: 'Certified Entities',
  cb: 'Certification Bodies',
  cert: 'Certifications',
  lp: 'Landing page',
  cb_profile: 'CB profile',
};

export const ANALYTICS_PAGEVIEWS_TYPE_AB = {
  ce: 'Certified Entities',
  cb: 'Certification Bodies',
  cert: 'Certifications',
  lp: 'Landing page',
};

export const ANALYTICS_PAGEVIEWS_TYPE_CB = {
  mp: 'Profile',
  cert: 'Certification',
};

export const LOCATIONS_TYPE_AB = {
  users: 'Users',
  cert: 'Certifications',
  ce: 'Certified Entities',
  cb: 'Certification Bodies',
};

export const LOCATIONS_TYPE_CB = {
  cert: 'Certifications',
  ce: 'Certified Entities',
};

export const CB_MARKET_TRENDS_CERT_TYPE = {
  own_count: 'Your Certifications',
  others_count: 'Other Certifications',
};

export const CB_MARKET_TRENDS_CERT_SITES_TYPE = {
  own_count: 'Your Sites',
  others_count: 'Other Sites',
};

export const CB_MARKET_TRENDS_CE_TYPE = {
  own_count: 'Your Certified Entities',
  others_count: 'Other Certified Entities',
};

export const CB_MARKET_TRENDS_CE_TYPE_COLOR = {
  own_count: '#370575', // primary
  others_count: '#2ED3CA', //secondary
};

export const CB_MARKET_TRENDS_LINE_CHART_CE = {
  own: 'Your Certified Entities',
  others: 'Other Certified Entities',
};

export const CB_MARKET_TRENDS_LINE_CHART_CERT = {
  own: 'Your Certifications',
  others: 'Other Certifications',
};

export const CB_MARKET_TRENDS_LINE_CHART_CERT_SITES = {
  own: 'Your Sites',
  others: 'Other Sites',
};

export const CB_MARKET_TRENDS_LINE_CHART_COLOR = {
  own: '#370575',
  others: '#2ED3CA',
};

export const CE_ACTIVITY_TYPE = {
  resolution: 'Resolve CE name',
  name_mapping_resolution: 'Resolved CE name conflict',
  conflict_with_ce_name: 'File upload error',
};

export const DATA_MAPPING_TYPES = {
  accreditation_status: 'Accreditation status',
  country: 'Country',
  standard: 'Standard',
  certification_status: 'Certification status',
  certification_type: 'Certification type',
  scheme: 'Scheme',
  sector: 'Sector',
};

export const SECTOR_GROUPS = {
  iaf: 'IAF Sectors',
  fs: 'Food Safety',
  cms: 'CMS',
  aero: 'Aerospace',
  abms: 'ABMS',
  others: 'Others',
};
