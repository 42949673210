import React, {createContext, useContext} from 'react';
import Stores from 'utils/stores';
import useQuery from 'hooks/useQuery';
import useLocale from '../hooks/useLocale';
import {PageLoading} from 'components/Loadings';

const CaptchaContext = createContext({});

function CaptchaProvider(props) {
  const {children} = props;

  const {query} = useQuery();
  const {isLoading, locale} = useLocale();

  const {country_code} = locale;
  const {captcha: queryCaptcha} = query;

  const captchaCode = getCode();

  function getCode() {
    let code;

    // 1. Check IP
    code = country_code === 'CN' ? 'h' : 'g';

    // 2. Check Localstorage
    if (Stores.captcha) {
      code = Stores.captcha;
    }

    // 3. Check URL query
    if (queryCaptcha) {
      code = queryCaptcha === 'h' ? 'h' : 'g';
    }

    // Save new code into localstorage
    Stores.captcha = code;

    return code;
  }

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <CaptchaContext.Provider
      value={{
        captchaCode: captchaCode || 'g',
      }}
    >
      {children}
    </CaptchaContext.Provider>
  );
}

const useCaptchaCode = () => useContext(CaptchaContext);

export {CaptchaContext, CaptchaProvider, useCaptchaCode};
