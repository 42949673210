import React from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'lodash/function';
import {Select} from 'components/Selects';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';

CBSelect.propTypes = {
  action: PropTypes.func.isRequired,
  optionLabel: PropTypes.string.isRequired,
  optionValue: PropTypes.string.isRequired,
};

CBSelect.defaultProps = {
  isClearable: true,
  placeholder: 'Select Certification Body',
  optionValue: 'certification_bodies_id',
  optionLabel: 'certification_bodies_name',
  ordered: {order_by: 'certification_bodies_name', order: 'ASC'},
};

function CBSelect(props) {
  const {
    action,
    name,
    defaultValue,
    optionLabel,
    optionValue,
    ordered,
    value,
    ...rest
  } = props;

  const [fetch, isLoading] = useFetch(action);
  const {alertByError} = useAlert();

  async function loadOptions(value, callback) {
    try {
      const {data: _data} = await fetch({
        limit: 10,
        order: ordered.order,
        order_by: ordered.order_by,
        filter: {
          search: value,
        },
      });
      return callback(_data);
    } catch (e) {
      await alertByError(e);
      return callback([]);
    }
  }

  return (
    <Select
      key={value || defaultValue}
      async
      isLoading={isLoading}
      name={name}
      getOptionLabel={(option) => option[optionLabel]}
      getOptionValue={(option) => option[optionValue]}
      defaultValue={defaultValue}
      value={value}
      cacheOptions
      loadOptions={debounce(loadOptions, 250)}
      {...rest}
    />
  );
}

export default CBSelect;
