import React, {useState, useEffect} from 'react';
import {Select} from 'components/Selects';
import {getStandardsVersionList} from 'api/standards';
import {debounce} from 'lodash/function';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';

function VersionSelect(props) {
  const {actions, name, defaultValue, filters, onChange, ...rest} = props;
  const {alertByError} = useAlert();

  const [value, setValue] = useState(defaultValue);
  const [options, setOptions] = useState([]);
  const [fetch, isLoading] = useFetch(getStandardsVersionList);

  useEffect(() => {
    const v = options.find((option) => {
      return option.standards_id === defaultValue;
    });
    setValue(v);
  }, [defaultValue, options]);

  async function loadOptions(value, callback) {
    try {
      let {data} = await fetch({
        limit: -1,
        order: 'ASC',
        order_by: 'standards_revision',
        filter: {
          search: value,
          ...filters,
        },
      });
      setOptions(data);
      return callback(data);
    } catch (e) {
      alertByError(e);
      return callback([]);
    }
  }

  return (
    <Select
      async
      name={name}
      isLoading={isLoading}
      value={value}
      defaultValue={defaultValue}
      onChange={(data) => {
        setValue(data);

        if (onChange) onChange(data);
      }}
      loadOptions={debounce(loadOptions, 250)}
      {...rest}
    />
  );
}

VersionSelect.defaultProps = {
  name: 'standards_revision',
  placeholder: 'Select or search by name',
  cacheOptions: true,
  defaultOptions: true,
  onChange: () => {},
  filters: {},
  defaultValue: '',
  getOptionLabel: (option) => [option.standards_revision],
  getOptionValue: (option) => [option.standards_revision],
};

export default VersionSelect;
