import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {extend} from 'lodash';
import {queryParse, queryStringify} from 'utils/http';
import {StatusSelect} from 'components/Selects';

FilterStatusSelect.propTypes = {
  source: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
};

FilterStatusSelect.defaultProps = {
  isClearable: true,
  placeholder: 'Filter by status',
  name: 'status',
};

function FilterStatusSelect({placeholder, name, ...rest}) {
  const {search, pathname} = useLocation();
  const history = useHistory();

  function handleChange(selected) {
    let query = queryParse(search);

    delete query.limit;
    delete query.offset;

    if (selected) {
      query = extend(query, {[name]: selected.value});
    } else {
      delete query.status;
    }

    history.push({
      pathname: pathname,
      search: queryStringify(query),
    });
  }

  return (
    <StatusSelect
      onChange={handleChange}
      defaultValue={queryParse(search)[name] || ''}
      placeholder={placeholder}
      {...rest}
    />
  );
}

export default FilterStatusSelect;
