import React from 'react';
import PropTypes from 'prop-types';
import {Select} from 'components/Selects';
import useRegions from 'hooks/useRegions';

RegionSelect.propTypes = {
  isClearable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

RegionSelect.defaultProps = {
  isClearable: false,
  name: 'region_id',
  placeholder: 'Search / Select region',
  optionLabel: 'region',
  optionValue: 'region_id',
};

function RegionSelect(props) {
  const {
    name,
    defaultValue = [],
    optionLabel,
    optionValue,
    secondaryName,
    ...rest
  } = props;
  const {isLoading, regions} = useRegions();

  const _defaultValue = defaultValue.map((value) => {
    return regions.find((region) => region.region_id === parseInt(value));
  });

  return (
    <Select
      key={isLoading + _defaultValue}
      isLoading={isLoading}
      name={name}
      getOptionLabel={(option) => option[optionLabel]}
      getOptionValue={(option) => option[optionValue]}
      options={regions}
      defaultValue={_defaultValue}
      {...rest}
    />
  );
}

export default RegionSelect;
