import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import withObjectSource from './withObjectSource';

ExpirationPeriodSelect.propTypes = {
  name: PropTypes.string,
  source: PropTypes.object.isRequired,
};

ExpirationPeriodSelect.defaultProps = {
  isClearable: false,
  placeholder: 'Select...',
  name: 'expiry_type',
  source: {},
};

function ExpirationPeriodSelect(props) {
  const {name, ...rest} = props;

  return <Select name={name} {...rest} />;
}

export default withObjectSource(ExpirationPeriodSelect);
