import {useState, useEffect} from 'react';
import useAlert from 'hooks/useAlert';

import Cookies from 'utils/cookies';
import {postAuthCaptcha} from 'api/auth';
import useLocale from './useLocale';

function useCaptchaModal(initialCheck, onClose) {
  const {alertByError} = useAlert();

  const {locale} = useLocale();
  const {country_code} = locale;

  const captchaToken = Cookies.captchaToken || '';
  const isVerified = !!captchaToken;

  const [state, setState] = useState({
    isLoading: false,
    countryCode: country_code,
    isVerified,
    captchaToken,
    isOpen: initialCheck && !isVerified,
  });

  useEffect(() => {
    if (initialCheck) {
      verifyCaptcha();
    }
  }, []);

  function verifyCaptcha() {
    const captchaToken = Cookies.captchaToken || '';
    const isVerified = !!captchaToken;

    setState((prevState) => {
      return {
        ...prevState,
        captchaToken: captchaToken,
        isVerified: isVerified,
        isOpen: !isVerified,
      };
    });
  }

  async function handleClose(e) {
    e.preventDefault();

    if (onClose) {
      onClose();
    } else {
      setState({isOpen: false});
    }
  }

  async function handleVerifyCaptcha(code, type) {
    try {
      setState((prevState) => {
        return {...prevState, isLoading: true};
      });

      const {data} = await postAuthCaptcha(type, {
        response: code,
      });
      const {token_id} = data;
      Cookies.captchaToken = token_id;

      setState((prevState) => {
        return {
          ...prevState,
          isLoading: false,
          isVerified: true,
          isOpen: false,
        };
      });
    } catch (e) {
      alertByError(e);
      throw e;
    }
  }

  return {
    isOpen: state.isOpen,
    isVerified: state.isVerified,
    verifyCaptcha: handleVerifyCaptcha,
    handleClose,
  };
}
export default useCaptchaModal;
