import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useQuery from 'hooks/useQuery';
import {Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';
import {Icon} from 'components/Icons';
import SearchFilterForm from './SearchFilterForm';
import debounce from 'lodash.debounce';

SearchFilterInput.propsTypes = {
  name: PropTypes.string.isRequired,
  flush: PropTypes.bool,
  addonType: PropTypes.oneOf(['prepend', 'append']),
};

SearchFilterInput.defaultProps = {
  name: 'search',
  placeholder: 'Search',
  flush: true,
  addonType: 'prepend',
};

function SearchFilterInput(props) {
  const {name, placeholder, flush, addonType} = props;
  const {query, updateQuery} = useQuery();

  const defaultValue = query[name];

  function handleChange(e) {
    const value = e.target.value;

    if (!value) {
      updateQuery({[name]: ''});
    } else {
      updateQuery({[name]: value});
    }
  }

  const handleChangeDebounce = useCallback(debounce(handleChange, 500));

  return (
    <SearchFilterForm>
      <InputGroup
        className={cx('input-group-merge', {
          'input-group-flush': flush,
        })}
      >
        <InputGroupAddon addonType={addonType}>
          <InputGroupText>
            <Icon type="tio" icon="search" />
          </InputGroupText>
        </InputGroupAddon>

        <Input
          type="search"
          aria-label="Search"
          autoComplete="off"
          name={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={handleChangeDebounce}
        />
      </InputGroup>
    </SearchFilterForm>
  );
}

export default SearchFilterInput;
