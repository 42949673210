const MARKET_TRENDS = {
  ACCESS: 'market_trends.access',

  CBS: 'market_trends.certification_bodies.view',
  CERTS: 'market_trends.certifications.view',
  CES: 'market_trends.certified_entities.view',
  LOCATIONS: 'market_trends.locations.view',
  PAGEVIEWS: 'market_trends.pageviews.view',
  USERS: 'market_trends.users.view',
  SITES: 'market_trends.sites.view',
  SECTORS: 'market_trends.sectors.view',
};

const CBS = {
  ACCESS: 'certification_bodies.access',

  OVERVIEW: {
    ADD: 'certification_bodies.overview.add',
  },

  ACTIVITY: 'certification_bodies.activity.view',
  ANALYTICS: 'certification_bodies.analytics.view',

  VIEWS: {
    ACTIVATION: 'certification_bodies.details.activation',
    EDIT: 'certification_bodies.details.edit',
    WITHDRAW_ACCREDITATION:
      'certification_bodies.details.withdraw_accreditation',

    STANDARDS_SCHEMES: {
      VIEW: 'certification_bodies.details/standards_schemes.view',
      ASSIGN: 'certification_bodies.details/standards_schemes.assign',
      EDIT: 'certification_bodies.details/standards_schemes.edit',
      DELETE: 'certification_bodies.details/standards_schemes.delete',
    },
    TECHNICAL_SECTORS: {
      VIEW: 'certification_bodies.details/technical_sectors.view',
      EDIT: 'certification_bodies.details/technical_sectors.edit',
      ASSIGN: 'certification_bodies.details/technical_sectors.assign',
      DELETE: 'certification_bodies.details/technical_sectors.delete',
    },
    OFFICE_DIRECTORIES: {
      ADD: 'certification_bodies.details/office_directories.add',
      EDIT: 'certification_bodies.details/office_directories.edit',
      VIEW: 'certification_bodies.details/office_directories.view',
      DELETE: 'certification_bodies.details/office_directories.delete',
    },
    ANALYTICS: 'certification_bodies.details/analytics.view',
  },
};

const CES = {
  ACCESS: 'certified_entities.access',

  OVERVIEW: {
    CREATE: 'certified_entities.overview.create',
  },

  VIEWS: {
    EDIT: 'certified_entities.details.edit',

    DELETE_PERMANENT: 'certified_entities.details.permanent_delete',
    CERTS: {
      ADD: 'certified_entities.details/certifications.add',
    },
    ACTIVITY: 'certified_entities.details/activity.view',
  },
};

const CERTS = {
  ACCESS: 'certifications.access',

  OVERVIEW: {
    DOWNLOAD: 'certifications.overview.download',
    PERMANENT_DELETE_ALL: 'certifications.overview.permanent_delete_all',
  },

  VIEWS: {
    EDIT: 'certifications.details.edit',
    RENEW: 'certifications.details.renew',
    DELETE: 'certifications.details.delete',
    PERMANENT_DELETE: 'certifications.details.permanent_delete',

    SITES: {
      VIEW: 'certifications.details/additional_sites.view',
      ADD: 'certifications.details/additional_sites.add',
      EDIT: 'certifications.details/additional_sites.edit',
      DELETE: 'certifications.details/additional_sites.delete',
    },
    TECHNICAL_SECTORS: {
      VIEW: 'certifications.details/technical_sectors.view',
      ASSIGN: 'certifications.details/technical_sectors.assign',
      DELETE: 'certifications.details/technical_sectors.delete',
    },
    RENEWAL_HISTORY: {
      VIEW: 'certifications.details/renewal_history.view',
      EDIT: 'certifications.details/renewal_history.edit',
      DELETE: 'certifications.details/renewal_history.delete',
    },
    UPDATE_HISTORY: 'certifications.details/update_history.view',
  },
};

const LIBRARIES = {
  ACCESS: 'libraries.access',

  OVERVIEW: {
    ASSIGN: 'libraries.standards_schemes.assign',
  },

  VIEWS: {
    CERTIFICATES: 'libraries.standards_schemes/details.assigned_certificates',
    DELETE: 'libraries.standards_schemes/details.delete',
    EDIT: 'libraries.standards_schemes/details.edit',
  },

  UKAS_SECTORS: {
    VIEW: 'libraries.ukas_sectors.view',
    DELETE: 'libraries.ukas_sectors.delete',
    ASSIGN: 'libraries.ukas_sectors.assign',
  },
  SECTORS_LIBRARY: {
    VIEW: 'libraries.sectors_library.view',
    DELETE: 'libraries.sectors_library.delete',
    ADD: 'libraries.sectors_library.add',
  },
  SCHEMES_LIBRARY: {
    VIEW: 'libraries.schemes_library.view',
    DELETE: 'libraries.schemes_library.delete',
    ADD: 'libraries.schemes_library.add',
  },
  STANDARDS_LIBRARY: {
    VIEW: 'libraries.standards_library.view',
    DELETE: 'libraries.standards_library.delete',
    ADD: 'libraries.standards_library.add',
  },
};

const DATA_MANAGEMENT = {
  ACCESS: 'data_management.access',

  FILE_UPLOAD: {
    DOWNLOAD: 'data_management.file_upload.download',
    UPLOAD: 'data_management.file_upload.upload',
  },
  FTP_ACCESS: {
    VIEW: 'data_management.ftp_access.view',
    EDIT: 'data_management.ftp_access.edit',
    SYNC: 'data_management.ftp_access.sync',
    DOWNLOAD: 'data_management.ftp_access.download',
  },
  API_ACCESS: {
    VIEW: 'data_management.api_access.view',
    ADD: 'data_management.api_access.add',
    DELETE: 'data_management.api_access.delete',
  },
  DATA_MAPPING: {
    VIEW: 'data_management.data_mapping.view',
    DELETE: 'data_management.data_mapping.delete',
    EDIT: 'data_management.data_mapping.edit',
  },
  FIELD_MAPPING: {
    VIEW: 'data_management.field_mapping.view',
    DELETE: 'data_management.field_mapping.delete',
    EDIT: 'data_management.field_mapping.edit',
  },
  DATE_FORMAT_MAPPING: {
    VIEW: 'data_management.date_format_mapping.view',
    DISABLE: 'data_management.date_format_mapping.disable',
    ENABLE: 'data_management.date_format_mapping.enable',
  },
  ACTIVITY: {
    VIEW: 'data_management.activity.view',
  },
  CE_MAPPING: {
    BULK_RESOLVE: 'data_management.ce_mapping.bulk_resolve',
    RESOLVE: 'data_management.ce_mapping.resolve',
    VIEW: 'data_management.ce_mapping.view',
  },
};

const ENQUIRIES = {
  ACCESS: 'enquiries.access',

  VIEWS: {
    RESOLVE: 'enquiries.details.resolve',
    REOPEN: 'enquiries.details.reopen',
  },
};

const USERS = {
  ACCESS: 'users_permissions.access',

  INVITE: 'users_permissions.users.invite',
  EDIT: 'users_permissions.users.edit',
  DELETE: 'users_permissions.users.delete',
  PERMANENT_DELETE: 'users_permissions.users.permanent_delete',

  ROLES: {
    ADD: 'users_permissions.roles_permissions.add',
    DELETE: 'users_permissions.roles_permissions.delete',
    EDIT: 'users_permissions.roles_permissions.edit',
    VIEW: 'users_permissions.roles_permissions.view',
  },
};

const CB_PROFILE = {
  ACCESS: 'profile.access',

  RESIGN_ACCREDITATION: 'profile.overview.resign_accreditation',
  EDIT: 'profile.overview.edit',
};

const BRANCHES = {
  ACCESS: 'branches.access',

  OVERVIEW: {
    ADD: 'branches.overview.add',
  },

  VIEWS: {
    ACTIVATION: 'branches.details.activation',
    DELETE: 'branches.details.delete',
    EDIT: 'branches.details.edit',
  },
};

const SCOPES = {
  ACCESS: 'scopes.access',

  OFFICE_DIRECTORIES: 'scopes.office_directories.view',
  TECHNICAL_SECTORS: {
    VIEW: 'scopes.technical_sectors.view',
    ASSIGN: 'scopes.technical_sectors.assign',
    DELETE: 'scopes.technical_sectors.delete',
  },
};

const SETTINGS = {
  ACCESS: 'settings.access',

  BRANCH: {
    EDIT: 'settings.branch.edit',
    VIEW: 'settings.branch.view',
  },
};

export const PERMISSIONS = {
  MARKET_TRENDS,
  CBS,
  CES,
  CERTS,
  LIBRARIES,
  DATA_MANAGEMENT,
  ENQUIRIES,
  USERS,
  CB_PROFILE,
  BRANCHES,
  SCOPES,
  SETTINGS,
};
