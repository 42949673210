import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'lodash/function';
import {Select} from 'components/Selects';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';

SchemeSelect.propTypes = {
  action: PropTypes.func.isRequired,
  optionLabel: PropTypes.string.isRequired,
  optionValue: PropTypes.string.isRequired,
};

SchemeSelect.defaultProps = {
  isClearable: true,
  name: 'scheme_id',
  optionValue: 'scheme_id',
  optionLabel: 'scheme_name',
  placeholder: 'i.e. Quality Management Systems',
};

function SchemeSelect(props) {
  const {
    id,
    action,
    name,
    defaultValue,
    optionLabel,
    optionValue,
    filter,
    ...rest
  } = props;
  const [fetch, isLoading] = useFetch(action);
  const {alertByError} = useAlert();

  const [value, setValue] = useState(defaultValue);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const v = options.find((option) => {
      return option.scheme_id === defaultValue;
    });
    setValue(v);
  }, [defaultValue, options]);

  async function loadOptions(value, callback) {
    const query = {
      limit: 100,
      order: 'ASC',
      order_by: 'scheme_name',
      filter: {
        search: value,
        ...filter,
      },
    };

    try {
      const {data} = id ? await fetch(id, query) : await fetch(query);
      setOptions(data);
      return callback(data);
    } catch (e) {
      await alertByError(e);
      return callback([]);
    }
  }

  return (
    <Select
      async
      isLoading={isLoading}
      name={name}
      getOptionLabel={(option) => option[optionLabel]}
      getOptionValue={(option) => option[optionValue]}
      value={value}
      defaultValue={defaultValue}
      onChange={(data) => {
        setValue(data);
      }}
      loadOptions={debounce(loadOptions, 250)}
      noOptionsMessage={({inputValue}) =>
        inputValue
          ? `There is no result for '${inputValue}'.'`
          : 'Search and select the correct scheme name.'
      }
      {...rest}
    />
  );
}

export default SchemeSelect;
