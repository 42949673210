const customStyles = {
  container: (provided, state) => {
    return {
      ...provided,
      maxWidth: '300px',
      flexGrow: '1',
    };
  },

  control: (provided, state) => {
    return {
      ...provided,
      cursor: 'pointer',
      background: state.isDisabled ? '#f8fafd' : '#fff',
      borderColor: state.isFocused ? 'rgba(51,0,114,.4)' : '#e7eaf3',
      borderRadius: 0,
      boxShadow: state.isFocused ? '0 0 10px rgba(51, 0, 114, 0.1)' : 0,
      '&:hover': {
        borderColor: state.isFocused ? 'rgba(51,0,114,.4)' : '#e7eaf3',
      },
      minHeight: '42px',
      tabIndex: -1,
    };
  },
  menu: (provided) => {
    return {
      ...provided,
      zIndex: 5,
      textOverflow: 'elipsis',
      overflow: 'hidden',
    };
  },

  valueContainer: (provided) => ({
    ...provided,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'text',
    flexWrap: 'nowrap', // temp
  }),

  placeholder: (provided) => ({
    ...provided,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    color: '#97a4af',
  }),

  option: (provided, state) => {
    return {
      ...provided,
      // backgroundColor: state.isFocused ? 'black' : 'gray',
    };
  },

  multiValue: (provided, state) => {
    return {
      ...provided,
      cursor: 'pointer',
    };
  },

  input: (provided, state) => {
    return {
      ...provided,
      overflow: 'hidden',
      // minWidth: '20%',
    };
  },
};

export default customStyles;
