import trim from 'lodash/trim';

export const UKAS_TITLE = 'UKAS CertCheck';
export const UKAS_SUPPORT_EMAIL = 'support@certcheck.ukas.com';
export const UKAS_CERTCHECK_SITE = 'CertCheck.UKAS.com';

export const IAF_TITLE = 'IAF CertSearch';

export const APP_STAGE =
  trim(process.env.REACT_APP_STAGE, ' ') || 'development';
export const IS_ENV_DEV = process.env.NODE_ENV === 'development';
export const API_URL = process.env.REACT_APP_API_URL;

export const PASSWORD_EXPIRY_DAYS = 90;
export const OPEN_LANDING = process.env.REACT_APP_OPEN_LANDING;
export const OPEN_MARKET_TRENDS = process.env.REACT_APP_OPEN_MT;
export const SHOW_CB_SEARCH_LINK = process.env.REACT_APP_OPEN_CB_SEARCH;
export const ENABLE_SECTOR_GROUP_FILTER =
  process.env.REACT_APP_ENABLE_MT_SECTOR_GROUP_FILTER;
//
// URLs
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const CDN_FILE_URL = process.env.REACT_APP_CDN_FILE_URL;

//
// Data Templates
export const DATA_TEMPLATE_VERSION =
  process.env.REACT_APP_DATA_TEMPLATE_VERSION;
// export const DATA_TEMPLATE_VERSION_2 =
//   process.env.REACT_APP_DATA_TEMPLATE_VERSION_2;

// export const DATA_TEMPLATE_VERSION_3 =
//   process.env.REACT_APP_DATA_TEMPLATE_VERSION_3;
//
// Cookies
export const COOKIE_DOMAIN = process.env.REACT_APP_DOMAIN;
export const COOKIE_PREFIX = process.env.REACT_APP_COOKIE_PREFIX;
export const COOKIE_KEY = process.env.REACT_APP_SECRET_KEY;

//
// Data Versions
export const CONSENT_VERSIONS = {
  terms: process.env.REACT_APP_CONSENT_VERSION_TERMS,
  privacy: process.env.REACT_APP_CONSENT_VERSION_PRIVACY,
  data: process.env.REACT_APP_CONSENT_VERSION_DATA,
  marketing: process.env.REACT_APP_CONSENT_VERSION_MARKETING,
};

//
// Keys
export const HCAPTCHA_SITEKEY = process.env.REACT_APP_HCAPTCHA_SITEKEY;
export const RECAPTCHA_SITEKEY = process.env.REACT_APP_RECAPTCA_SITEKEY;
export const AMCHART_KEY = process.env.REACT_APP_AMCHART_KEY;
export const AMCHART_MAP_KEY = process.env.REACT_APP_AMCHART_MAP_KEY;
