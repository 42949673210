import {useCallback} from 'react';
import {get, keys} from 'lodash/object';
import {useAlert as alert} from 'contexts/AlertContext';
import {isObject} from 'lodash/lang';
import {ERROR_MESSAGES} from 'configs';

function useAlert() {
  const {show, hide} = alert();

  const alertSuccess = useCallback(
    (options) => {
      return show({
        iconType: 'success',
        icon: 'check-circle',
        title: 'Success',
        ...options,
      });
    },
    [show]
  );

  const alertByError = useCallback(
    async (error, options) => {
      const {code, message} = get(error, 'errors', {});

      let text = '';
      if (isObject(message)) {
        // Multiple messages
        keys(message).forEach((key) => {
          if (text) text += '\n';
          text += message[key];
        });
      } else {
        if (ERROR_MESSAGES[code]) {
          text = ERROR_MESSAGES[code];
        } else {
          if (message) {
            text = message;
          } else {
            text = ERROR_MESSAGES.not_found;
          }
        }
      }
      return show({
        iconType: 'danger',
        icon: 'times-circle',
        title: 'Error occurred',
        text,
        ...options,
      });
    },
    [show]
  );

  const alertError = useCallback(
    (options) => {
      return show({
        iconType: 'danger',
        icon: 'times-circle',
        title: 'Failure',
        ...options,
      });
    },
    [show]
  );

  const alertConfirm = useCallback(
    (options) => {
      return show({
        iconType: 'warning',
        icon: 'exclamation-circle',
        title: 'Confirmation',
        okText: 'Confirm',
        cancelButton: true,
        ...options,
      });
    },
    [show]
  );

  return {
    alertSuccess,
    alertError,
    alertConfirm,
    alertByError,
    hideAlert: hide,
  };
}

export default useAlert;
