import React from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'lodash/function';
import {Select} from 'components/Selects';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';

CESelect.propTypes = {
  action: PropTypes.func.isRequired,
  optionLabel: PropTypes.string.isRequired,
  optionValue: PropTypes.string.isRequired,
  defaultValue: PropTypes.shape({
    company_id: PropTypes.string.isRequired,
    company_name: PropTypes.string,
  }),
};

CESelect.defaultProps = {
  isClearable: true,
  placeholder: 'Select Certified Entity',
  optionValue: 'company_id',
  optionLabel: 'company_name',
};

function CESelect(props) {
  const {
    action,
    name,
    defaultValue,
    optionLabel,
    optionValue,
    filter,
    ...rest
  } = props;
  const [fetch, isLoading] = useFetch(action);
  const {alertByError} = useAlert();

  async function loadOptions(value, callback) {
    const {certification_bodies_id} = filter || {};

    const query = {
      limit: 100,
      order: 'ASC',
      order_by: 'company_name',
      filter: {
        search: value,
        certification_bodies_id,
      },
    };

    try {
      const {data} = await fetch(query);
      return callback(data);
    } catch (e) {
      await alertByError(e);
      return callback([]);
    }
  }

  return (
    <Select
      async
      isLoading={isLoading}
      name={name}
      getOptionLabel={(option) => option[optionLabel]}
      getOptionValue={(option) => option[optionValue]}
      defaultValue={defaultValue}
      loadOptions={debounce(loadOptions, 250)}
      {...rest}
    />
  );
}

export default CESelect;
