import {useState} from 'react';
import {first, has, keys, omit} from 'lodash';
import useQuery from 'hooks/useQuery';
import {
  CustomInput,
  Option,
  Control,
  ValueContainer,
  MultiValueLabel,
  NoOptionsMessage,
} from './advancedSearchComponents';
import customStyles from './advancedSearchStyles';

const _components = {
  Input: CustomInput,
  Option,
  Control,
  ValueContainer,
  MultiValueLabel,
  NoOptionsMessage,
  DropdownIndicator: null,
};

const typeQueryKey = 'keyword_type';

function useHandleAdvancedSearch(props) {
  const {types, name} = props;

  const {query, updateQuery} = useQuery();
  const {filter} = query || {};

  // storing keyword type (any, all)
  const [keywordType, setKeywordType] = useState(
    has(filter, typeQueryKey) ? filter[typeQueryKey] : first(keys(types))
  );

  function toggleKeywordType(keywordObj) {
    const _keywordType = keywordObj['value'];

    setKeywordType(_keywordType);

    if (has(filter, typeQueryKey)) {
      updateQuery({filter: {...filter, [typeQueryKey]: _keywordType}});
    }
  }

  // updating/clearing query
  function updateSearchQuery(searchValues) {
    updateQuery({
      filter: {
        ...filter,
        [typeQueryKey]: keywordType,
        [name]: searchValues,
      },
    });
  }

  function clearSearchQuery() {
    updateQuery({
      filter: {...omit(filter, [name, typeQueryKey])},
    });
  }

  // create an option
  function createOption(value) {
    return {
      label: value,
      value,
    };
  }

  return {
    customStyles,
    components: _components,

    keywordType: keywordType,
    toggleKeywordType,

    updateSearchQuery,
    clearSearchQuery,

    createOption,
  };
}

export default useHandleAdvancedSearch;
