import React, {useEffect, useState} from 'react';
import {InputGroup} from 'reactstrap';
import {SelectInput} from 'components/Selects';
import CreatableSelect from 'react-select/creatable';
import useHandleAdvancedSearch from './useHandleAdvancedSearch';
import {size} from 'lodash';
import SearchFilterAdvancedTooltip from './SearchFilterAdvancedTooltip';
import {
  KeywordSingleValue,
  KeywordDropdownIndicator,
} from './advancedSearchComponents';

const KEYWORD_TYPE = {
  all: 'All keywords',
  any: 'Any keywords',
};

function SearchFilterAdvanced(props) {
  const {name, defaultValue, ...rest} = props;

  const {
    customStyles,
    components,
    keywordType,
    toggleKeywordType,
    updateSearchQuery,
    clearSearchQuery,
    createOption,
  } = useHandleAdvancedSearch({
    name,
    types: KEYWORD_TYPE,
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchValues, setSearchValues] = useState([] || defaultValue);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setSearchValues([...defaultValue.map((value) => createOption(value))]);
    }
  }, [defaultValue]);

  function handleFocusBlur(e) {
    if (e.type === 'focus') return setIsMenuOpen(true);
    return setIsMenuOpen(false);
  }

  function handleInputChange(value) {
    setInputValue(value);
  }

  function handleOnChange(keywordValue, meta) {
    const {action} = meta;

    switch (action) {
      case 'create-option':
        // pressing  enter/tab on nonexisting option creation
        setSearchValues((prev) => [...prev, keywordValue]);
        updateSearchQuery([...keywordValue.map((item) => item.value)]);
        break;

      case 'remove-value':
        // manually clicking the x button on the value
        if (size(searchValues) <= 1) return clearSearchQuery();
        updateSearchQuery([...keywordValue.map((item) => item.value)]);
        break;

      case 'deselect-option':
        // pressing enter on the focused existing option
        if (!inputValue) {
          if (size(searchValues) <= 1) return clearSearchQuery();
          setSearchValues(keywordValue);
          updateSearchQuery([...keywordValue.map((item) => item.value)]);
        }
        break;

      case 'pop-value':
        // using backspace
        if (size(searchValues) <= 1) return clearSearchQuery();
        updateSearchQuery(
          searchValues.slice(0, size(searchValues) - 1).map((v) => v.value)
        );
        break;

      case 'clear':
        // clicking the input's x button
        clearSearchQuery();
        break;

      default:
        break;
    }
  }

  function handleOnKeyDown(e, d) {
    if (e.key === ' ' || e.key === 'Tab') {
      if (inputValue) return;
      e.preventDefault();
    }
  }

  const keywordTypeComponents = {
    IndicatorSeparator: null,
    SingleValue: KeywordSingleValue,
    DropdownIndicator: KeywordDropdownIndicator,
  };

  return (
    <InputGroup className="input-group-merge">
      <SelectInput
        components={keywordTypeComponents}
        name="keyword_type"
        className="flex-grow-1"
        style={{maxWidth: '130px'}}
        defaultValue={keywordType}
        isSearchable={false}
        source={KEYWORD_TYPE}
        onChange={toggleKeywordType}
      />
      <CreatableSelect
        name={name}
        value={searchValues}
        styles={customStyles}
        options={searchValues}
        components={components}
        inputValue={inputValue}
        createOptionPosition="first"
        onChange={handleOnChange}
        hideSelectedOptions={false}
        onInputChange={handleInputChange}
        placeholder={`Search scope by ${keywordType} keywords`}
        onKeyDown={handleOnKeyDown}
        backspaceRemovesValue
        isClearable
        isMulti
        menuIsOpen={isMenuOpen}
        onFocus={(e) => handleFocusBlur(e)}
        onBlur={(e) => handleFocusBlur(e)}
        {...rest}
      />
      <SearchFilterAdvancedTooltip />
    </InputGroup>
  );
}

export default SearchFilterAdvanced;
