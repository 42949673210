import cx from 'classnames';
import {Icon} from 'components/Icons';
import {components} from 'react-select';
import {isEmpty, truncate} from 'lodash';

const Control = (props) => {
  const {children} = props;

  return (
    <components.Control {...props}>
      <Icon type="tio" icon="search" className="mx-2 text-muted" />
      {children}
    </components.Control>
  );
};

const Option = (props) => {
  const {children, isSelected, value, ...rest} = props;

  return (
    <components.Option {...rest}>
      <div className="d-flex flex-row justify-content-between">
        <span className="overflow-hidden">{value}</span>
        <Icon
          type="fa"
          icon="check"
          color="success"
          className={cx('align-self-center', {
            'd-none': !isSelected,
          })}
        />
      </div>
    </components.Option>
  );
};

const NoOptionsMessage = (props) => {
  const {options, ...rest} = props;
  return (
    <components.NoOptionsMessage {...rest}>
      {isEmpty(options) && 'Enter keyword'}
    </components.NoOptionsMessage>
  );
};

// main value container
// ** children:
// Placeholder
// Input
const ValueContainer = (props) => {
  const {children, ...rest} = props;

  return (
    <components.ValueContainer {...rest}>
      <span className="d-flex">{children}</span>
    </components.ValueContainer>
  );
};

const MultiValueLabel = (props) => {
  const {children, ...rest} = props;

  return (
    <components.MultiValueLabel {...rest}>
      {truncate(children, {length: 33})}
    </components.MultiValueLabel>
  );
};

const CustomInput = (props) => {
  const {children, ...rest} = props;

  return <components.Input {...rest}>{children}</components.Input>;
};

function KeywordSingleValue(props) {
  const {children, ...rest} = props;

  return (
    <components.SingleValue {...rest}>
      <span className="text-muted">{children}</span>
    </components.SingleValue>
  );
}

const KeywordDropdownIndicator = (props) => {
  const {icon, ...rest} = props;

  return (
    <components.DropdownIndicator {...rest}>
      <Icon type="fa" icon={icon || 'chevron-down'} />
    </components.DropdownIndicator>
  );
};

export {
  CustomInput,
  Option,
  Control,
  ValueContainer,
  MultiValueLabel,
  NoOptionsMessage,
  // keyword_type select
  KeywordDropdownIndicator,
  KeywordSingleValue,
};
