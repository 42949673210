import {useState, useEffect} from 'react';

import Cookies from 'utils/cookies';
import fingerprint from 'utils/fingerprint';

/**
 * Browser Unique ID (fingerprint)
 * @returns {{isLoading: boolean, bsid: string}}
 */
function useBSID() {
  const [bsid, setBSID] = useState(Cookies.bsid || null);

  // Set BSID
  useEffect(() => {
    if (!bsid) {
      fingerprint().then((visitorId) => {
        Cookies.bsid = visitorId; // store in cookie
        setBSID(visitorId);
      });
    }
  }, [bsid]);

  return {
    isLoading: !bsid,
    bsid,
  };
}

export default useBSID;
