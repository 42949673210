import {useState, useEffect} from 'react';
import {isEmpty} from 'lodash/lang';
import {getPublicIndustries} from 'api/public';
import useAlert from 'hooks/useAlert';
import Stores from 'utils/stores';

function useIndustries() {
  const {alertByError} = useAlert();

  const storedData = Stores.industries || [];

  const [state, setState] = useState({
    isLoading: isEmpty(storedData), // Start as loading, if localstorage is empty
    data: storedData,
  });

  const {isLoading, data} = state;

  useEffect(() => {
    function callbackSuccess({data}) {
      Stores.industries = data; // Set localstorage
      setState({data, isLoading: false});
    }

    async function callbackFailure(e) {
      await alertByError(e);
    }

    async function fetch() {
      return await getPublicIndustries();
    }

    if (isEmpty(data)) {
      fetch().then(callbackSuccess, callbackFailure);
    } else {
      setState((current) => ({...current, isLoading: false}));
    }
  }, [data, alertByError]);

  return {
    isLoading,
    industries: data,
  };
}

export default useIndustries;
