import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {get, isEmpty, pickBy} from 'lodash';
import {StandardSelect} from 'components/Selects';

ObjectStandardSchemeSelect.propTypes = {
  async: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  defaultOptions: PropTypes.bool,
  isClearable: PropTypes.bool,
  action: PropTypes.func.isRequired,
  name: PropTypes.shape({standard: PropTypes.string, scheme: PropTypes.string}),
};

ObjectStandardSchemeSelect.defaultProps = {
  async: true,
  cacheOptions: true,
  defaultOptions: true,
  isClearable: true,
  placeholder: 'Search or Select by Standard and Scheme',
  name: {
    standard: 'standards_id',
    scheme: 'scheme_id',
  },
};

function ObjectStandardSchemeSelect(props) {
  const {
    name,
    standards_name,
    scheme_name,
    standards_id,
    scheme_id,
    onChange,
    filter,
    isEdit,
    ...rest
  } = props;
  const defaultValue = {standards_id, scheme_id};
  const defaultName = {standards_name, scheme_name};
  const [selected, setSelected] = useState(defaultValue);
  const [selectedName, setSelectedName] = useState(defaultName);
  const {scheme_type} = filter;
  const isProdCert = scheme_type === 'Product Certification';

  useEffect(() => {
    const name = pickBy(defaultName);
    const value = pickBy(defaultValue);
    setSelected(value);
    setSelectedName(name);
  }, []);

  function handleChange(selected) {
    const {standards_id, scheme_id, standards_name, scheme_name} =
      selected || {};
    if (!isEmpty(selected)) {
      setSelectedName({standards_name, scheme_name});
      setSelected({standards_id, scheme_id});
    } else {
      setSelectedName('');
      setSelected({});
    }

    if (onChange) onChange(selected);
  }
  let isValid = true;
  const {standard, scheme} = name;
  const {standards_id: _standard, scheme_id: _scheme} = selected || {};

  if (isProdCert) {
    isValid = !!_standard || !!_scheme;
  } else {
    isValid = !!_standard && !!_scheme;
  }

  return (
    <>
      <input
        type="hidden"
        name={standard}
        value={selected.standards_id || ''}
      />

      <input
        type="hidden"
        name={scheme}
        value={get(selected, 'scheme_id', '')}
      />

      <StandardSelect
        className={
          !isValid &&
          isEdit &&
          'is-invalid border border-danger rounded react-select--invalid'
        }
        value={isEmpty(selectedName) ? '' : selectedName}
        onChange={handleChange}
        getOptionLabel={(option) => {
          return `${
            !option['standards_name'] ? 'No Standard' : option['standards_name']
          } - ${!option['scheme_name'] ? 'No Scheme' : option['scheme_name']}`;
        }}
        getOptionValue={(option) => ({
          standards_id: option.standards_id,
          scheme_id: option.scheme_id,
        })}
        defaultValue={standards_id || (isProdCert && isValid && ' ')}
        filter={filter}
        required
        cacheOptions
        {...rest}
      />
    </>
  );
}

export default ObjectStandardSchemeSelect;
