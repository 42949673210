import React, {useEffect, useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import useAlert from 'hooks/useAlert';
import useFetch from 'hooks/useFetch';
import {Select} from 'components/Selects';
import {debounce} from 'lodash';
import {getSectorsGroup} from 'api/sectors';

SelectSectorGroup.propTypes = {
  getOptionLabel: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
};

SelectSectorGroup.defaultProps = {
  isClearable: true,
  name: 'sectors_group_id',
  getOptionValue: (option) => option.sectors_group_id,
  getOptionLabel: (option) => option.sectors_group_name,
  placeholder: 'Select or search sector group',
};

function SelectSectorGroup(props) {
  const {
    id,
    name,
    defaultValue,
    getOptionValue,
    getOptionLabel,
    filter,
    ...rest
  } = props;
  const {alertByError} = useAlert();
  const [fetch, isLoading] = useFetch(getSectorsGroup);
  const [value, setValue] = useState(defaultValue);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const v = options.find((option) => {
      return option.sectors_group_id === defaultValue;
    });
    setValue(v);
  }, [defaultValue, options]);

  async function loadOptions(value, callback) {
    const query = {
      limit: -1,
      order: 'ASC',
      order_by: 'sectors_group_name',
      filter: {
        search: value,
        ...filter,
      },
    };

    try {
      const {data} = id ? await fetch(id, query) : await fetch(query);
      setOptions(data);
      return callback(data);
    } catch (e) {
      await alertByError(e);
      return callback([]);
    }
  }

  return useMemo(
    () => (
      <Select
        async
        isLoading={isLoading}
        name={name}
        value={value}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={(data) => {
          setValue(data);
        }}
        loadOptions={debounce(loadOptions, 250)}
        noOptionsMessage={({inputValue}) =>
          inputValue
            ? `There is no result for '${inputValue}'.'`
            : 'Search and select sector group.'
        }
        defaultOptions
        {...rest}
      />
    ),
    [isLoading]
  );
}

export default SelectSectorGroup;
