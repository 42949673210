const ERROR_MESSAGE = {
  //none_exist_account: `You have entered an invalid account or password. Please try again.`,
  //wrong_password: `You have entered an invalid account or password. Please try again.`,
  activation_consent: `You have to accept the terms and conditions.`,
  already_deleted: `It is already deleted. Please check status and try again.`,
  already_exist: `The resource already exist.`,
  api_keys_quota_reached: `You reached the maximum number of API keys for your account. Please contact support.`,
  bounced_email: `The Email has been bounced.`,
  certification_inactive: `The certification is either inactive, hidden or already been deleted.`,
  company_already_active: `This company is already active.`,
  company_guids: `Unique ID is required to update.`,
  company_invalid: `Invalid company. Please create correct request.`,
  duplicate_scheme: `The Scheme already exists in the library.`,
  duplicate_standard: `The Standard already exists in the library.`,
  empty_values: `The request has the empty value(s) or required field(s). Please provide valid values.`,
  excel_file_malformed: `Excel file malformed. Please check your excel file and data formats. See user guide for more information`,
  excel_has_errors: `Excel file has missing a required input. Please check data-management activities.`,
  excel_malformed: `There is a data has wrong format in your sheet. Please see requirements in the User Guide to ensure the data format meets the requirement.`,
  exist_email: `The email address is already in use. Please provide alternate email address.`,
  exist_session: `Session has already been activated. Please create new request.`,
  exist_username: `The user name is already in use. Please provide alternate user name.`,
  failure_create: `The Request has failed. Please try again.`,
  ftp_connection_failed: `FTP connection Failed. Check your host, username and password.`,
  ftp_details_not_found: `We couldn't find your FTP server. Please check your FTP settings.`,
  ftp_not_active: `Your FTP server connection is not active. Please check your FTP settings.`,
  invalid_activation_url: `This link already has been activated or expired. Please check the link again.`,
  invalid_cb_id: `File has invalid Identity Number data. Please check data-management activities.`,
  invalid_email: `Email address has invalid format. Please provide valid email address.`,
  invalid_email_domain: `Email address has invalid format. Please provide valid email address.`,
  invalid_mapping_count: `Complete "Data mapping" or "Field Mapping" and re-upload your file.`,
  invalid_otp_code: `Invalid OTP Code. Please try again.`,
  invalid_requirement: `Invalid requirement.`,
  invalid_session_bsid: `The session has expired. Please log in to your account again.`,
  invalid_session_otp: `Session doesn't have otp.`,
  invalid_session_permission: `Session doesn't have right permission to do action.`,
  invalid_session_token: `The session has expired. Please log in to your account again.`,
  invalid_status: `Your account is invalid. Please contact your organization's administrator.`,
  ip_search_limit_reached: `Your IP address reached the daily search limit. Please sign up or log in to increase your limit.`,
  no_otp: `Your account requires Google Authentication.`,
  no_session: `The session has expired. Please create new request.`,
  no_session_key: `Who are you? (No Session Key).`,
  not_found: `Your request could not be found. Please check and try again.`,
  search_limit_reached: `You reached the daily search limit. Please sign up or upgrade your account.`,
  session_creation_failed: `Session creation has failed.`,
  session_token_empty: `Session token must not be empty.`,
  unauthorized_account_access: `You are not authorized to access this account. Please check the account permissions.`,
  unauthorized_company_access: `You are not authorized to access this company. Please check the company permissions.`,
  upload_file_errors: `Resolve errors detailed in "File Upload Activities" and re-upload your file.`,
  wrong_codes: `Invalid code. Please provide valid code.`,
  wrong_credentials: `Unable to log in with provided credentials.`,
  wrong_email: `Email address has invalid format. Please provide valid email address.`,
  wrong_mapping: `Wrong mapping. Please enter correct mapping values.`,
  wrong_otp: `Invalid authentication code. Please provide valid code.`,
  wrong_params: `The request includes invalid parameters. Please provide valid parameters.`,
  wrong_params_cert: `Please provide certification correctly.`,
  wrong_type: `The request includes invalid type(s). Please provide valid type(s).`,
  timeout: 'Timeout error',
};

export default ERROR_MESSAGE;
