import urlJoin from 'url-join';
import {CDN_URL} from 'constants/environment';

function join(file) {
  return urlJoin(CDN_URL, file);
}

export const IMG_LOGO_DEFAULT_PNG = join(
  '/assets/images/logos/UKAS_logo_736.png'
);

export const IMG_EMPTY_DATA = join('/assets/images/public/no-record-found.png');

export const IMG_LOGO_DEFAULT = join(
  'assets/images/logos/svg/ukas-logo-default.svg'
);
export const IMG_LOGO_LIGHT = join(
  'assets/images/logos/svg/ukas-logo-white.svg'
);
export const IMG_LOGO_GREY = join('assets/images/logos/ukas-logo-footer.png');
export const IMG_LOGO_IAF = join('assets/images/logos/svg/iaf-logo-120w.svg');
export const IMG_LOGO_FOOTER_PRIMARY = join(
  'assets/images/logos/ukas-logo-footer-primary.png'
);

export const IMG_LOGO_CROWN_DEFAULT = join(
  'assets/images/logos/ukas-icon-18w.png'
);
export const IMG_LOGO_CROWN_LIGHT = join(
  'assets/images/logos/ukas-icon-18w-white.png'
);
export const IMG_LOGO_CROWN_GREY = join(
  'assets/images/logos/ukas-icon-18w-gray.png'
);

export const IMG_LOGOS = {
  light: IMG_LOGO_LIGHT,
  dark: IMG_LOGO_DEFAULT,
  grey: IMG_LOGO_GREY,
  primary: IMG_LOGO_FOOTER_PRIMARY,
};

export const IMG_LOGOS_CROWN = {
  light: IMG_LOGO_CROWN_LIGHT,
  dark: IMG_LOGO_CROWN_DEFAULT,
  grey: IMG_LOGO_CROWN_GREY,
};

export const ONLY_ACCEPTED_IMAGE = {
  image: 'image/*',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  png: 'image/png',
  all: 'image/jpg, image/jpeg, image/png, image/gif',
};

export const IMAGES_FEEDBACK = {
  image: 'Only accepts Image',
  jpg: 'Only accepts JPG file type',
  jpeg: 'Only accepts JPEG file type',
  gif: 'Only accepts GIF file type',
  png: 'Only accepts PNG file type',
  // all: 'Only accepts JPG, JPEG, PNG, and GIF file type',
  all: 'This file type is unsupported. This tool supports JPG, JPEG, PNG, and GIF files.',
};

export function validateImage(fileType) {
  return Object.values(ONLY_ACCEPTED_IMAGE).indexOf(fileType) > -1;
}
