import {merge} from 'lodash';
import React from 'react';
import {AsyncPaginate} from 'react-select-async-paginate';

const customStyles = (styles) => {
  return {
    container: (provided) => {
      return {
        ...provided,
        ...styles,
      };
    },

    control: (provided, state) => {
      return {
        ...provided,
        cursor: 'pointer',
        background: state.isDisabled ? '#f8fafd' : '#fff',
        borderColor: state.isFocused ? 'rgba(51,0,114,.4)' : '#e7eaf3',
        borderRadius: 0,
        boxShadow: state.isFocused ? '0 0 10px rgba(51, 0, 114, 0.1)' : 0,
        '&:hover': {
          borderColor: state.isFocused ? 'rgba(51,0,114,.4)' : '#e7eaf3',
        },
        minHeight: '42px',
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        zIndex: 5,
      };
    },

    valueContainer: (provided) => ({
      ...provided,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),

    placeholder: (provided) => ({
      ...provided,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '95%',
      overflow: 'hidden',
      color: '#97a4af',
    }),
  };
};

const _components = {
  IndicatorSeparator: null,
};

function SelectPaginate(props) {
  const {
    name,
    defaultValue,
    placeholder,
    isDisabled,
    disabled,
    onChange,
    options,
    styles,
    components,
    ...rest
  } = props;

  return (
    <AsyncPaginate
      name={name}
      options={options}
      onChange={onChange}
      components={merge({}, components, _components)}
      placeholder={placeholder}
      defaultValue={defaultValue}
      styles={customStyles(styles)}
      isDisabled={disabled || isDisabled}
      {...rest} // debounceTimeout, isSearchable, isClearable, className, required
    />
  );
}

export default SelectPaginate;
