import {Icon} from 'components/Icons';
import {Image} from 'components/Images';
import React from 'react';
import {UncontrolledTooltip} from 'reactstrap';

function SearchFilterAdvancedTooltip() {
  return (
    <div className="d-flex align-items-center ml-2">
      <Icon type="fa" icon="info-circle" id="advanced-search" color="primary" />
      <UncontrolledTooltip
        target="advanced-search"
        placement="right"
        style={{
          maxWidth: 'max-content',
          textAlign: 'left',
        }}
        fade={false}
      >
        <div className="p-3">
          <small>
            <strong>ALL keywords:</strong> Search for certification scope
            containing ALL of the entered keywords.
            <br />
            <strong>ANY keywords:</strong> Search for certification scope
            containing ANY keywords.
            <br />
            <br />
            <strong>Note:</strong> Keywords are added individually by pressing
            the enter button from your keyboard.
            <br />
            Keywords are added by tags.
            <br />
            <br />
            See examples below:
            <br />
            <strong>If entered at once:</strong> food manufacturing = 1 keyword
            <br />
            <Image
              src="https://img.certcheck.ukas.com/assets/images/public/single-keyword.png"
              width="300px"
            />
            <br />
            <br />
            <strong>If entered individually:</strong> food = 1 keyword ;
            manufacturing = 1 keyword ; Total keywords = 2
            <br />
            <Image
              src="https://img.certcheck.ukas.com/assets/images/public/multiple-keyword.png"
              width="300px"
            />
          </small>
        </div>
      </UncontrolledTooltip>
    </div>
  );
}

export default SearchFilterAdvancedTooltip;
