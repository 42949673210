import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import useCountries from 'hooks/useCountries';
import equal from 'deep-equal';
import {isEmpty} from 'lodash';

CountrySelect.propTypes = {
  isClearable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

CountrySelect.defaultProps = {
  isClearable: false,
  name: 'countries_id',
  placeholder: 'Search / Select economy',
  optionLabel: 'name',
  optionValue: 'countries_id',
  isMulti: false,
};

function getCountyOptions(countries, regions, isMulti) {
  if (!regions || (isEmpty(regions) && isMulti)) {
    return countries;
  }

  if (isMulti && !isEmpty(regions)) {
    return countries.filter((item) => {
      return regions.some((region) => item.region_id === parseInt(region));
    });
  }

  if (regions && !isMulti) {
    return countries.filter((item) => {
      return item.region_id === parseInt(regions);
    });
  }
}

function CountrySelect(props) {
  const {
    name,
    defaultValue = [],
    optionLabel,
    optionValue,
    secondaryName,
    regionId,
    isMulti,
    ...rest
  } = props;
  const {isLoading, selectCountries} = useCountries();

  const _countries = getCountyOptions(selectCountries, regionId, isMulti);

  const _defaultValue = isMulti
    ? defaultValue.map((value) => {
        return selectCountries.find(
          (country) => country.countries_id === parseInt(value)
        );
      })
    : defaultValue;

  return (
    <Select
      key={isLoading + regionId}
      isLoading={isLoading}
      name={name}
      getOptionLabel={(option) => option[optionLabel]}
      getOptionValue={(option) => option[optionValue]}
      options={_countries}
      defaultValue={
        isMulti
          ? _defaultValue
          : _countries.find((option) =>
              equal(option[secondaryName || name], defaultValue)
            )
      }
      isMulti={isMulti}
      {...rest}
    />
  );
}

export default CountrySelect;
