import {useState, useEffect} from 'react';
import {orderBy} from 'lodash/collection';
import {isEmpty} from 'lodash/lang';
import {getPublicRegions} from 'api/public';
import Stores from 'utils/stores';
import useAlert from './useAlert';

function useRegions() {
  const storedData = Stores.regions || [];

  const {alertByError} = useAlert();
  const [state, setState] = useState({
    isLoading: isEmpty(storedData), // Start as loading, if localstorage is empty
    data: storedData,
  });

  const {isLoading, data} = state;

  useEffect(() => {
    function callbackSuccess({data}) {
      Stores.regions = data; // Set localstorage
      setState({data, isLoading: false});
    }

    async function callbackFailure(e) {
      await alertByError(e);
    }

    async function fetch() {
      return await getPublicRegions();
    }

    if (isEmpty(data)) {
      fetch().then(callbackSuccess, callbackFailure);
    } else {
      setState((current) => ({...current, isLoading: false}));
    }
  }, [data]);

  return {
    isLoading,
    regions: orderBy(data, ['name'], 'asc'),
  };
}

export default useRegions;
