import React from 'react';
import equal from 'deep-equal';
import useIndustries from 'hooks/useIndustries';
import Select from './Select';

IndustrySelect.defaultProps = {
  isClearable: false,
  name: 'industry_id',
  placeholder: 'Select Sector/Industry',
};

function IndustrySelect(props) {
  const {name, secondaryName, defaultValue, ...rest} = props;
  const {isLoading, industries} = useIndustries();

  const _defaultValue = industries.find((option) =>
    equal(option[secondaryName || name], defaultValue)
  );

  return (
    <Select
      key={isLoading}
      isLoading={isLoading}
      name={name}
      getOptionLabel={(option) => option.industry_name}
      getOptionValue={(option) => option.industry_id}
      defaultValue={_defaultValue}
      options={industries}
      {...rest}
    />
  );
}

export default IndustrySelect;
