import React, {createContext, useState, useContext} from 'react';
const LayoutContext = createContext({});

const initialLayout = {
  header: false,
  search: false,
  footer: false,
  aside: false,
  asideOpen: false,
  fluid: true,
};

// Provider
function LayoutProvider(props) {
  const {children} = props;

  const [layout, setLayout] = useState(initialLayout);

  function updateState(layout) {
    setLayout((prevState) => ({
      ...prevState,
      ...layout,
    }));
  }

  const {header, search, footer, aside, asideOpen, fluid, navLinks, isPublic} =
    layout;

  return (
    <LayoutContext.Provider
      value={{
        header,
        search,
        footer,
        aside,
        asideOpen,
        fluid,
        navLinks,
        isPublic,
        setLayout: (layout) => updateState(layout),
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

// Consumer
const LayoutConsumer = LayoutContext.Consumer;

// Hook
const useLayout = () => useContext(LayoutContext);

export {LayoutProvider, LayoutConsumer, useLayout};
