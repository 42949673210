import React, {useEffect, useState} from 'react';
import {Select} from 'components/Selects';
import {debounce, drop, find, first, isEqual} from 'lodash';
import {timestampToLocale} from 'utils/dates';
import {components} from 'react-select';
import {Icon} from 'components/Icons';
import cx from 'classnames';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';
import {useParams} from 'react-router';

function Option(props) {
  const {children, isSelected, ...rest} = props;

  return (
    <components.Option {...rest}>
      <div className="d-flex flex-row justify-content-between">
        <span
          className={cx('overflow-hidden', {
            'text-muted': isSelected,
          })}
        >
          {children}
        </span>
        <Icon
          type="fa"
          icon="check"
          color="success"
          className={cx('align-self-center', {
            'd-none': !isSelected,
          })}
        />
      </div>
    </components.Option>
  );
}

const _components = {
  Option,
  IndicatorSeparator: null,
};

function SelectCertVersion(props) {
  const {defaultValue, isCurrent, action, ...rest} = props;

  const {certId} = useParams();
  const {alertByError} = useAlert();

  const [value, setValue] = useState(defaultValue);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (defaultValue) {
      const {created_at, company_certifications_id} = defaultValue || {};

      setValue(
        find(options, (item) =>
          isEqual(
            item[!isCurrent ? 'created_at' : 'company_certifications_id'],
            !isCurrent ? +created_at : company_certifications_id
          )
        )
      );
    }
  }, [options, defaultValue]);

  const [fetch, isLoading] = useFetch(action, {
    initialLoading: false,
  });

  async function loadOptions(inputValue, callback) {
    try {
      const {data} = await fetch(certId);

      const _data = isCurrent ? [first(data)] : drop(data);

      setOptions(_data);
      return callback(_data);
    } catch (error) {
      alertByError(error);
      return callback([]);
    }
  }

  return (
    <Select
      async
      value={value}
      name="select_version"
      components={_components}
      style={{minWidth: '230px'}}
      placeholder="Select Previous Version"
      loadOptions={debounce(loadOptions, 250)}
      onChange={(selected) => setValue(selected)}
      getOptionValue={(option) => option.created_at}
      getOptionLabel={(option) =>
        timestampToLocale(option.created_at, true, false)
      }
      disabled={isCurrent || isLoading}
      isSearchable={false}
      defaultOptions
      {...rest}
    />
  );
}

export default SelectCertVersion;
