import {useEffect} from 'react';
import useFetch from 'hooks/useFetch';
import useAlert from './useAlert';
import {getEmergencyPlugStatus} from 'api/settings';

function useGetMaintenanceStatus() {
  const {alertByError} = useAlert();

  const [fetch, isLoading, response] = useFetch(getEmergencyPlugStatus, {
    initialLoading: true,
  });

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      await fetch();
    } catch (e) {
      await alertByError(e);
    }
  }

  const {data} = response || {};
  const {is_maintenance} = data || {};

  return {isLoading, isMaintenance: is_maintenance};
}

export default useGetMaintenanceStatus;
