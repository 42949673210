import React from 'react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import Select from './Select';

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  source: PropTypes.object.isRequired,
};
SelectInput.defaultProps = {
  isClearable: false,
  placeholder: 'Select status',
  source: {},
};

function SelectInput(props) {
  const {defaultValue, source, ...rest} = props;

  const _options = Object.keys(source).map((key) => ({
    label: source[key],
    value: key,
  }));

  const _defaultValue = _options.find((option) =>
    equal(option.value, defaultValue)
  );

  return <Select options={_options} {...rest} defaultValue={_defaultValue} />;
}

export default SelectInput;
