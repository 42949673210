import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactSelect, {components} from 'react-select';
import ReactSelectAsync from 'react-select/async';

import {Icon} from 'components/Icons';

const DropdownIndicator = (props) => {
  const {icon, ...rest} = props;

  return (
    <components.DropdownIndicator {...rest}>
      <Icon type="fa" icon={icon || 'chevron-down'} />
    </components.DropdownIndicator>
  );
};

Select.propTypes = {
  async: PropTypes.bool,
  search: PropTypes.bool,
  isClearable: PropTypes.bool,
  options: PropTypes.array.isRequired,
};

Select.defaultProps = {
  async: false,
  search: false,
  className: 'react-select-container',
  classNamePrefix: 'react-select',
  isClearable: false,
  isSearchable: true,
  options: [],
};

const customStyles = (style) => {
  return {
    container: (provided) => {
      return {
        ...provided,
        ...style,
      };
    },

    control: (provided, state) => {
      return {
        ...provided,
        cursor: 'pointer',
        background: state.isDisabled ? '#f8fafd' : '#fff',
        borderColor: state.isFocused ? 'rgba(51,0,114,.4)' : '#e7eaf3',
        borderRadius: 0,
        boxShadow: state.isFocused ? '0 0 10px rgba(51, 0, 114, 0.1)' : 0,
        '&:hover': {
          borderColor: state.isFocused ? 'rgba(51,0,114,.4)' : '#e7eaf3',
        },
        minHeight: '42px',
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        zIndex: 5,
      };
    },

    valueContainer: (provided) => ({
      ...provided,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),

    placeholder: (provided) => ({
      ...provided,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '95%',
      overflow: 'hidden',
      color: '#97a4af',
    }),
  };
};

function Select(props) {
  const {
    async,
    search,
    name,
    value,
    defaultValue,
    disabled,
    isDisabled,
    options,
    className,
    required,
    onChange,
    components,
    style,
    ...rest
  } = props;

  // Setting for validation
  const [selectedValue, setSelectedValue] = useState(defaultValue || value);

  const _className = cx(className, {
    'react-select--valid': required && selectedValue,
    'react-select--invalid': required && !selectedValue,
  });

  function handleChange(selected) {
    setSelectedValue(selected);
    if (onChange) onChange(selected);
  }

  // render react-select
  return React.createElement(async ? ReactSelectAsync : ReactSelect, {
    name,

    className: _className,
    styles: customStyles(style),
    components: components
      ? components
      : {
          IndicatorSeparator: null,
          DropdownIndicator: (props) => (
            <DropdownIndicator
              icon={search ? 'search' : 'chevron-down'}
              {...props}
            />
          ),
        },

    value,
    defaultValue,
    options,
    //defaultValue: options.find((option) => equal(option[name], defaultValue)),

    isDisabled: disabled || isDisabled,
    required,
    onChange: handleChange,
    ...rest,
  });
}

export default Select;
