import {SelectPaginate} from 'components/Selects';
import useAlert from 'hooks/useAlert';
import useFetch from 'hooks/useFetch';
import useQuery from 'hooks/useQuery';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {timestampToLocale} from 'utils/dates';
import {components} from 'react-select';
import cx from 'classnames';
import {Icon} from 'components/Icons';
import {drop, find, first, isEqual} from 'lodash';

function Option(props) {
  const {children, isSelected, ...rest} = props;

  return (
    <components.Option {...rest}>
      <div className="d-flex flex-row justify-content-between">
        <span
          className={cx('overflow-hidden', {
            'text-muted': isSelected,
          })}
        >
          {children}
        </span>
        <Icon
          type="fa"
          icon="check"
          color="success"
          className={cx('align-self-center', {
            'd-none': !isSelected,
          })}
        />
      </div>
    </components.Option>
  );
}

const _components = {
  Option,
};

function SelectCertVersionPaginated(props) {
  const {action, defaultValue, isCurrent, ...rest} = props;

  const {query} = useQuery();
  const {ceId} = useParams();
  const {alertByError} = useAlert();

  const [options, setOptions] = useState([]);
  const [nextKey, setNextKey] = useState(null);
  const [value, setValue] = useState(defaultValue);

  const [fetch] = useFetch(action, {
    initialLoading: false,
  });

  useEffect(() => {
    if (defaultValue) {
      const {created_at, company_certifications_id} = defaultValue || {};

      setValue(
        find(options, (item) =>
          isEqual(
            item[!isCurrent ? 'updated_at' : 'company_certifications_id'],
            !isCurrent ? created_at : company_certifications_id
          )
        )
      );
    }
  }, [options, defaultValue]);

  async function loadOptions(search, prevOptions) {
    const _query = {
      ...query,
      key: nextKey,
      limit: '20',
    };

    try {
      const {data: dataResponse, meta} = await fetch(ceId, _query);
      const {next_key} = meta || {};

      const _data = isCurrent ? [first(dataResponse)] : drop(dataResponse);

      setOptions([...prevOptions, ...dataResponse]);
      setNextKey(next_key);

      return {
        options: _data,
        hasMore: !!next_key,
      };
    } catch (error) {
      alertByError(error);
    }
  }

  return (
    <SelectPaginate
      async
      value={value}
      defaultOptions
      name="select_version"
      components={_components}
      loadOptions={loadOptions}
      styles={{minWidth: '230px'}}
      placeholder="Select Previous Version"
      onChange={(selected) => setValue(selected)}
      getOptionValue={(option) => option.created_at}
      getOptionLabel={(option) =>
        timestampToLocale(option.created_at, true, false)
      }
      isSearchable={false}
      disabled={isCurrent}
      {...rest}
    />
  );
}

export default SelectCertVersionPaginated;
