import {useEffect} from 'react';
import {getCompanyRolesList, getBranchCompanyRolesList} from 'api/members';
import useFetch from './useFetch';

function useGetRolesList(branchId) {
  const [fetch, isLoading, response] = useFetch(
    branchId ? getBranchCompanyRolesList : getCompanyRolesList,
    {
      initialLoading: true,
    }
  );

  useEffect(() => {
    fetch(branchId);
  }, []);

  const {data = [], meta = {}} = response;

  return {roles: data || [], isFetching: isLoading, meta};
}

export default useGetRolesList;
