import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'lodash/function';
import {Select} from 'components/Selects';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';
import equal from 'deep-equal';
import useQuery from 'hooks/useQuery';

SelectSectorNormal.propTypes = {
  action: PropTypes.func.isRequired,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
};

SelectSectorNormal.defaultProps = {
  isClearable: true,
  placeholder: 'Select or Search Sector',
  optionValue: 'sectors_id',
  optionLabel: 'sectors_name',
};

function SelectSectorNormal(props) {
  const {
    action,
    name,
    defaultValue,
    optionLabel,
    optionValue,
    filter,
    ...rest
  } = props;

  const [fetch, isLoading] = useFetch(action);

  const {query} = useQuery();
  const {sectors_group_id} = query || {};

  const {alertByError} = useAlert();

  const [value, setValue] = useState(defaultValue);

  async function loadOptions(value, callback) {
    const query = {
      limit: 100,
      order: 'ASC',
      order_by: 'sectors_name',
      filter: {
        search: value,
        sector_group_ids: [sectors_group_id],
        ...filter,
      },
    };

    try {
      const {data} = await fetch(query);

      const _defaultValue = data.find((option) =>
        equal(
          option.sectors_code.concat(' - ', option.sectors_name),
          defaultValue
        )
      );

      setValue(_defaultValue);

      return callback(data);
    } catch (e) {
      await alertByError(e);
      return callback([]);
    }
  }

  return (
    <Select
      async
      isLoading={isLoading}
      name={name}
      getOptionLabel={(option) => option[optionLabel]}
      getOptionValue={(option) => option[optionValue]}
      value={value}
      onChange={(selected) => setValue(selected)}
      loadOptions={debounce(loadOptions, 250)}
      {...rest}
    />
  );
}

export default SelectSectorNormal;
