import {useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {extend} from 'lodash/object';
import urlJoin from 'url-join';
import cleanDeep from 'clean-deep';
import {queryParse, queryStringify} from 'utils/routes';

function useQuery() {
  const history = useHistory();
  const {pathname, search} = useLocation();

  const query = useMemo(() => {
    return queryParse(search);
  }, [search]);

  function mergeQuery(objQuery) {
    return extend({}, query, objQuery);
  }

  function buildQueryURL(objQuery) {
    const cleanedQuery = cleanDeep(objQuery);
    const options = {addQueryPrefix: true};
    const strQuery = queryStringify(cleanedQuery, options);

    return urlJoin(pathname, strQuery);
  }

  function pushQuery(objQuery) {
    const url = buildQueryURL(objQuery);
    history.push(url);
  }

  function updateQuery(objQuery, resetOffset = true) {
    let extendedQuery = mergeQuery(objQuery);

    if (resetOffset) {
      delete extendedQuery.offset; // Reset pagination query
    }

    const url = buildQueryURL(extendedQuery);
    history.push(url);
  }

  return {query, pushQuery, updateQuery, buildQueryURL};
}

export default useQuery;
