import {buildRouteNodes} from 'utils/routes';

const CERTS = {
  INDEX: '/certifications',

  LIST: '/',
  CREATE: '/create',

  VIEWS: {
    INDEX: '/:certId',
    VIEW: '/',
    EDIT: '/edit',
    SITES: '/additional-sites',
    TECHNICAL_SECTORS: '/technical-sectors',
    RENEWAL_HISTORY: '/renewal-history',
    UPDATE_HISTORY: {
      INDEX: '/update-history',

      LIST: '/',
      COMPARE_VERSIONS: '/:versionId',
    },
  },
};

const CES = {
  INDEX: '/certified-entities',

  LIST: '/',
  CREATE: '/create',
  EDIT: '/edit/:ceId',

  VIEWS: {
    INDEX: '/:ceId',
    VIEW: '/',
    EDIT: '/edit',
    ACTIVITY: '/activity',
    UPDATE_HISTORY: {
      INDEX: '/update-history',

      LIST: '/',
      COMPARE_VERSIONS: '/:versionId',
    },
    CERTS,
  },
};

const CBS = {
  INDEX: '/certification-bodies',

  LIST: '/',
  CREATE: '/create',
  ANALYTICS: '/analytics',
  ACTIVITY: '/activity',

  VIEWS: {
    INDEX: '/:cbId',
    VIEW: '/',
    EDIT: '/edit',
    STANDARDS: '/standards',
    SECTORS: '/technical-sectors',
    DIRECTORIES: '/office-directories/',
    // COUNTRIES: '/countries', // Removed, 16 Sep 2021
    ANALYTICS: '/analytics',
    CERTS,
    CES,
  },
};

const CB_PROFILE = {
  INDEX: '/cb-profile',

  VIEW: '/',
  EDIT: '/edit',
};

const ENQUIRIES = {
  INDEX: '/enquiries',

  OPEN: '/open',
  RESOLVED: '/resolved',
  // ARCHIVED: '/archived', -- TBD

  VIEWS: {
    INDEX: '/:enquiryId',
    VIEW: '/',
  },
};

const SETTINGS = {
  INDEX: '/settings',
  USER: '/',
  SECURITY: {
    INDEX: '/security',
    OVERVIEW: '/',
    DEVICE: '/device/:deviceId',
  },
  DATA: '/data',
  NOTIFICATIONS: '/notifications',
  DATA_SYNC: '/data-sync',
  INACTIVITY_TIMEOUT: '/inactivity-timeout',
  ACCOUNT: '/account',
  EMERGENCY_SITE: '/emergency-site-maintenance',
};

const SCOPES = {
  INDEX: '/scopes',

  LIST: '/',
  SECTORS: '/technical-sectors',
  // COUNTRIES: '/countries', // Removed, 16 Sep 2021
  DIRECTORIES: '/office-directories',
  ANALYTICS: '/analytics',
};

const LIBRARIES = {
  INDEX: '/libraries',

  LIST: '/standard-scheme',
  IAF_MLA: '/iaf-mla',
  UKAS_SECTORS: '/ukas-sectors',
  // Global Sectors
  SECTORS_LIBRARY: {
    INDEX: '/sectors-library',
    SECTORS: '/sectors',
    GROUP: '/group',
  },

  SCHEMES: '/schemes', // Schemes
  STANDARDS: '/standards', // Standard codes
  ANALYTICS: '/analytics',

  VIEWS: {
    INDEX: '/:standardId',
    VIEW: '/',
    CERTIFICATES: '/certificates',
  },
};

const USERS = {
  INDEX: '/users',
  LIST: '/',

  ROLES: {
    INDEX: '/roles',
    LIST: '/',
    CREATE: '/create',

    VIEWS: {
      INDEX: '/:roleId',
      VIEW: '/',
      EDIT: '/edit',
    },
  },

  VIEWS: {
    INDEX: '/:userId',
    VIEW: '/',
    EDIT: '/edit',
  },
};

const BRANCHES = {
  INDEX: '/branches',
  LIST: '/',
  CREATE: '/create',

  VIEWS: {
    INDEX: '/:branchId',
    VIEW: '/',
    USERS,
  },
};

// To be implemented if CIN exists remove
const DATA_MANAGEMENT = {
  INDEX: '/data-management',
  FILE_UPLOAD: '/file-upload',
  FTP_ACCESS: '/ftp-access',
  API_ACCESS: '/api',

  IMPORT_SETTINGS: {
    INDEX: '/import-settings',

    DATA_MAPPING: '/mapping',
    FIELD_MAPPING: '/field',
    CE_NAME_MAPPING: {
      INDEX: '/ce-name-mapping',
      LIST: '/',
      SETTINGS: '/settings',
    },
    DATE_FORMAT_SETTINGS: '/date-format-settings',
    CIN: '/cin',
  },
  ACTIVITY: '/activity',
};

const COMPANY_PROFILE = {
  INDEX: '/company-profile',
};

const MARKET_TRENDS = {
  INDEX: '/market-trends',
  OVERVIEW: '/',
  CBS: '/certification-bodies',
  CERTS: '/certifications',
  CES: '/certified-entities',
  LOCATIONS: '/locations',
  TRAFFIC: '/traffic',
  PAGE_VIEWS: '/page-views',
  USER: '/users',
  SECTORS: '/sectors',
};

const WATCHLIST = {
  INDEX: '/watch-list',
};

const SEARCH_HISTORY = {
  INDEX: '/search-history',

  CERTIFICATIONS: '/certifications',
  CERTIFIED_ENTITIES: '/certified-entities',
};

const DASHBOARD = {
  INDEX: '/dashboard',
  OVERVIEW: '/dashboard',
  SUPPORT: '/user-guide',
  ASSET_PACKS: '/asset-packs',

  WATCHLIST: buildRouteNodes(WATCHLIST),
  SEARCH_HISTORY: buildRouteNodes(SEARCH_HISTORY),
  MARKET_TRENDS: buildRouteNodes(MARKET_TRENDS),
  CB_PROFILE: buildRouteNodes(CB_PROFILE),
  COMPANY_PROFILE: buildRouteNodes(COMPANY_PROFILE),
  CBS: buildRouteNodes(CBS),
  CERTS: buildRouteNodes(CERTS),
  CES: buildRouteNodes(CES),
  ENQUIRIES: buildRouteNodes(ENQUIRIES),
  SETTINGS: buildRouteNodes(SETTINGS),
  SCOPES: buildRouteNodes(SCOPES),
  LIBRARIES: buildRouteNodes(LIBRARIES),
  USERS: buildRouteNodes(USERS),
  BRANCHES: buildRouteNodes(BRANCHES),
  DATA_MANAGEMENT: buildRouteNodes(DATA_MANAGEMENT),
};

export default DASHBOARD;
