import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useIdleTimer as useReactIdleTimer} from 'react-idle-timer';
import {useTimer} from 'react-timer-hook';

import {ROUTE_PATH} from 'constants/routes';
import {useAuth} from 'contexts/AuthContext';
import {Modal} from 'components/Modals';
import {Icon} from 'components/Icons';

const IdleTimerContext = React.createContext({});

function IdleTimerProvider(props) {
  const {children} = props;

  const history = useHistory();
  const {isAuth, account} = useAuth();
  const [isIdle, setIdle] = useState(false); // Flag for modal

  const {account_inactivity_timeout, account_inactivity_timeout_value} =
    account || {};
  const {time = 2} = account_inactivity_timeout_value || {};

  function handleStartIdle() {
    resetIdleTimer(); // use reset() instead of start()
  }

  function handleStopIdle() {
    setIdle(false);
    pauseIdleTimer();
  }

  function handleResetIdle() {
    setIdle(false);
    pauseTimer(); // pause timer
    resetIdleTimer(); // start idle timer
  }

  function handleOnIdle() {
    setIdle(true);
    const expiryTimestamp = new Date();
    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 30); // 30 seconds
    restartTimer(expiryTimestamp); // start timer
  }

  function handleLogout() {
    handleResetIdle();
    history.replace(ROUTE_PATH.LOG_OUT);
  }

  function handleModalOk() {
    handleResetIdle();
  }

  function handleModalCancel() {
    handleLogout();
  }

  //
  // Timer after idle
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 30); // 30 seconds

  const {
    seconds: timeLeft,
    pause: pauseTimer,
    restart: restartTimer,
  } = useTimer({
    expiryTimestamp,
    autoStart: false,
    onExpire: handleLogout,
  });

  //
  // Idle Timer
  const {
    reset: resetIdleTimer,
    pause: pauseIdleTimer,
    // getRemainingTime,
  } = useReactIdleTimer({
    timeout: 1000 * 60 * time, // unit: minutes
    onIdle: handleOnIdle,
    debounce: 5000,
    crossTab: true, // Enable cross tab event reconciliation.
    startOnMount: false, // Start the timer when the component mounts.
    startManually: true, // Require the timer to be started manually by calling
    stopOnIdle: true, // Stop the timer when user goes idle.
  });

  useEffect(() => {
    if (isAuth && account_inactivity_timeout === 1) {
      handleStartIdle();
    } else {
      handleStopIdle();
    }
  }, [isAuth, account_inactivity_timeout, time]);

  const clock = `00:${(timeLeft < 10 ? '0' : '') + timeLeft}`;

  return (
    <IdleTimerContext.Provider value={{}}>
      {children}

      <Modal
        toggle={false}
        isOpen={isIdle}
        header={
          <Icon
            type="fa"
            icon="exclamation-circle"
            className="text-warning"
            append="Your session is about to end"
          />
        }
        okText={`Stay signed in ${clock}`}
        okButtonProps={{onClick: handleModalOk}}
        cancelText={`Sign out`}
        cancelButtonProps={{onClick: handleModalCancel}}
      >
        {`You've been inactive for a while. For your security, we'll automatically sign you out. Choose 'Stay signed in' to continue or 'Sign out', if you're done.`}
      </Modal>
    </IdleTimerContext.Provider>
  );
}

export {IdleTimerProvider};
