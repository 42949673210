//
// REGEX_PATTERN
export const REGEX_PATTERN = {
  name: `^[a-zA-Z_ ]*$`,
  // it must be in the following order: characters@characters.domain (characters followed by an @ sign, followed by more characters, and then a "."
  // After the "." sign, you can only write 2 to 3 letters from a to z:
  email: `[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,8}$`,
  // Password: Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters
  password: `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\\\\!@#$%^&*()~_+={}|.,:;“’'"<>/\`\\?\\[\\]\\-\\s]).{12,24}$`,
  // it must start with http:// or https:// followed by at least one character:
  //website: `(https?:\\/\\/)?(www\\.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)|(https?:\\/\\/)?(www\\.)?(?!ww)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)`,
  //website: `https?://(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,10}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)`,
  website: `https?://(www\\.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)|https?://(www\\.)?(?!ww)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)`,
  // +### #### #### ####
  phone: `[\\+]\\d{1,3}[\\s?\\-?]?\\d{1,4}[\\s?\\-?]?\\d{3,4}[\\s?\\-?]?\\d{3,4}`,
  port: '^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$',
  host: '([a-z]{2,4}.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)|\b(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]).){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))\b',
  websiteNoProtocol: `(www\\.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)|(www\\.)?(?!ww)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)`,
};

//
// REGEX_FEEDBACK
export const REGEX_FEEDBACK = {
  required_fields: 'These fields are required.',
  required: 'This field is required.',
  required_email: 'Email is required. Please provide valid email address.',
  required_password: 'Password is required.',
  email: 'Please provide valid email address.',
  invalid_email: 'Please provide valid email address.',
  invalid_password:
    'Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.',
  invalid_confirm_password: 'Please make sure your passwords match.',
  website:
    'Please provide valid URL. Website must start with http:// or https://',
  phone:
    'Format must contain country code. Format must be plus(+), number, dash(-) or space. +xx xxx xxx xxxx',
  date: 'This field is required. Format must be YYYY-MM-DD',
  port: 'Port numbers should run from 0 to 65535',
  host: 'Must be 0.0.0.0 - 255.255.255.255 or ftp.example.com or example.com',
  websiteNoProtocol: 'Please provide valid URL.',
};
