import cleanDeep from 'clean-deep';
import useQuery from 'hooks/useQuery';
import {filter, first, isArray, isEmpty, last, omit, pick} from 'lodash';
import moment from 'moment';
import {useState} from 'react';

function convertDates(dates, expiryType) {
  if (expiryType === 'range') return dates;
  return (dates || []).map((date) => {
    return moment(new Date(date).toLocaleDateString()).format('M/YYYY');
  });
}

const expirationKeys = ['expiry_type', 'start_date', 'end_date'];
const advancedSearchKeys = ['certification_number', 'keyword_type', 'keywords'];

function useHandleSearchFilterInput() {
  const {query, pushQuery, updateQuery} = useQuery();
  const {filter: filterQuery} = query || {};

  const isEmptyQuery =
    isEmpty(query) || isEmpty(omit(filterQuery, advancedSearchKeys));

  const [isOpen, setIsOpen] = useState(false);
  function handleOpen() {
    setIsOpen((v) => !v);
  }

  function handleClearFilters(callback) {
    updateQuery({
      ...query,
      filter: {...filter, ...pick(filterQuery, advancedSearchKeys)},
    });
    if (callback) callback();
  }

  function handleClearFilter() {
    return pushQuery({filter: {...omit(filterQuery, expirationKeys)}});
  }

  function handleFilterChange(props) {
    const {queryName, selected, key, expiryType} = props || {};
    const {target} = selected || {};

    // clear query on no value selected
    if (!selected) {
      const value = isArray(key) ? key : queryName;
      return updateQuery({...query, filter: {...omit(filterQuery, value)}});
    }

    // default
    let queryValue = selected[queryName];

    // for radio buttons
    if (target) {
      queryValue = selected.target.value;
    }

    // for selects that returns an obj with specific info, e.g standards, ces, cbs
    if (key) {
      const _query = cleanDeep({
        [queryName]: selected[key],
        ...pick(selected, isArray(key) && key),
      });

      return updateQuery({...query, filter: {...filterQuery, ..._query}});
    }

    // for expiration period
    if (isArray(selected)) {
      const dates = convertDates(selected, expiryType);
      const startDate = first(dates);
      const endDate = last(dates);
      queryValue = {
        expiry_type: 'custom_range',
        start_date: startDate,
        end_date: endDate,
      };

      return updateQuery({...query, filter: {...filterQuery, ...queryValue}});
    }

    updateQuery({
      ...query,
      filter: {
        ...filterQuery,
        [queryName]: queryValue,
      },
    });
  }

  return {
    handleFilterChange,
    handleClearFilters,
    isEmptyQuery,
    isOpen,
    handleOpen,
    handleClearFilter,
  };
}

export default useHandleSearchFilterInput;
