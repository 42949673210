import React from 'react';
import useQuery from 'hooks/useQuery';
import {formSubmit} from 'utils/forms';
import {Form} from 'reactstrap';

SearchFilterForm.defaultProps = {
  submitButton: true,
};

function SearchFilterForm(props) {
  const {children} = props;
  const {updateQuery} = useQuery();

  return <Form onSubmit={formSubmit(updateQuery)}>{children}</Form>;
}

export default SearchFilterForm;
