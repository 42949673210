import React, {createContext, useEffect, useState} from 'react';
import {isEmpty} from 'lodash/lang';
import axios from 'axios';
import Stores from 'utils/stores';

const LocaleContext = createContext({});

function LocaleProvider(props) {
  const {children} = props;

  const storedData = Stores.locale || {};

  const [state, setState] = useState({
    isLoading: isEmpty(storedData), // Start as loading, if cookie is empty
    locale: storedData,
  });

  const {isLoading, locale} = state;

  useEffect(() => {
    if (isEmpty(locale)) {
      fetch().then(callbackSuccess, callbackFailure);
    } else {
      setState((current) => ({...current, isLoading: false}));
    }
  }, []);

  function callbackSuccess(res) {
    const {data} = res;

    const {country_calling_code, country_code, country_name, languages} = data;
    const newLocale = {
      country_calling_code,
      country_code,
      country_name,
      languages,
    };

    Stores.locale = newLocale; // Set localstorage
    setState({locale: newLocale, isLoading: false});
  }

  async function callbackFailure() {
    const newLocale = {
      country_calling_code: '',
      country_code: '',
      country_name: '',
      languages: 'en-GB',
    };

    Stores.locale = newLocale; // Set localstorage
    setState({locale: newLocale, isLoading: false});
  }

  function fetch() {
    let instance = axios.create();
    instance.interceptors.request.use(function (config) {
      delete config.headers.common['x-http-bsid']; // Remove
      delete config.headers.common['x-http-token']; // Remove
      return config;
    });

    return instance.get('https://ipapi.co/json');
  }

  if (isLoading) return 'Loading...';

  return (
    <LocaleContext.Provider
      value={{
        isLoading,
        locale,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
}

export {LocaleContext, LocaleProvider};
