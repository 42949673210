import React from 'react';
import useQuery from 'hooks/useQuery';
import {first} from 'lodash';
import {Limit} from 'components/Limits';

SearchFilterLimit.defaultProps = {
  name: 'limit',
  placeholder: 'Search',
};

function SearchFilterLimit(props) {
  const {meta, name} = props;
  const {total_rows} = meta;

  const {query, updateQuery} = useQuery();
  const defaultValue = query[name];

  function handleChange(e) {
    const value = e.target.value;
    updateQuery({limit: value});
  }

  const filters = [];
  if (total_rows >= 20) filters.push(20);
  if (total_rows >= 50) filters.push(50);
  if (total_rows >= 100) filters.push(100);

  return (
    <Limit
      total={total_rows}
      filters={filters}
      value={defaultValue || first(filters)}
      handleChange={handleChange}
    />
  );
}

export default SearchFilterLimit;
