import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {keys} from 'lodash/object';
import useQuery from 'hooks/useQuery';
import {
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  // Badge,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  Badge,
} from 'reactstrap';
// import {Icon} from 'components/Icons';
import SearchFilterForm from './SearchFilterForm';
import {isEmpty} from 'lodash';
import {Icon} from 'components/Icons';

SearchFilterDropdown.propTypes = {
  filterKeys: PropTypes.array.isRequired,
};

SearchFilterDropdown.defaultProps = {
  title: 'Filter',
  filterKeys: [],
};

function SearchFilterDropdown(props) {
  const {title, children, filterKeys} = props;

  const [isOpen, setOpen] = useState(false);
  const {query, pushQuery} = useQuery();

  useEffect(() => {
    setOpen(false);
  }, [query]);

  function handleClear(e) {
    e.preventDefault();
    pushQuery();
  }

  const isEmptyQuery = isEmpty(query);

  const queryKeys = keys(query);
  const count = filterKeys.filter((item) => queryKeys.includes(item)).length;

  const button = {
    color: 'white',
    children: (
      <>
        <Icon type="fa" icon="filter" append="Filter" />
        {count > 0 && (
          <Badge color="soft-dark" className="rounded-circle ml-2">
            {count}
          </Badge>
        )}
      </>
    ),
  };

  const dropdown = {
    className: 'p-0',
    right: true,
    style: {width: '320px', zIndex: '100'},
    children: isOpen && (
      <Card>
        <CardHeader className="p-3">
          <h5 className="card-header-title">{title}</h5>
          <Button close aria-label="Cancel" onClick={() => setOpen(!isOpen)} />
        </CardHeader>

        <CardBody className="p-3">
          <SearchFilterForm>
            {children}

            <Row className="mx-n2">
              <Col className="px-2">
                <Button
                  size="sm"
                  color="white"
                  block
                  disabled={isEmptyQuery}
                  onClick={handleClear}
                >
                  Clear filters
                </Button>
              </Col>

              <Col className="px-2">
                <Button size="sm" type="submit" color="primary" block>
                  Apply
                </Button>
              </Col>
            </Row>
          </SearchFilterForm>
        </CardBody>
      </Card>
    ),
  };

  return (
    <ButtonDropdown isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
      <DropdownToggle {...button} />

      {count > 0 && (
        <Button color="ghost-dark" onClick={handleClear}>
          Clear
        </Button>
      )}

      <DropdownMenu {...dropdown} />
    </ButtonDropdown>
  );
}

export default SearchFilterDropdown;
