import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash/object';
import {CBSelect} from 'components/Selects';

ObjectCBSelect.propTypes = {
  async: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  defaultOptions: PropTypes.bool,
  isClearable: PropTypes.bool,
  action: PropTypes.func.isRequired,
};

ObjectCBSelect.defaultProps = {
  async: true,
  cacheOptions: true,
  defaultOptions: true,
  isClearable: true,
  placeholder: 'Search / Select CB',
};

function ObjectCBSelect(props) {
  const {certification_bodies_id, certification_bodies_name, filter, ...rest} =
    props;

  const defaultValue = certification_bodies_id &&
    certification_bodies_name && {
      certification_bodies_id,
      certification_bodies_name,
    };

  const [selected, setSelected] = useState(defaultValue);

  function handleChange(selected) {
    const {certification_bodies_id, certification_bodies_name} = selected || {};
    setSelected(
      certification_bodies_id && certification_bodies_name
        ? {certification_bodies_id, certification_bodies_name}
        : ''
    );
  }

  return (
    <>
      <input
        type="hidden"
        name="certification_bodies_id"
        value={get(selected, 'certification_bodies_id', '')}
      />

      <input
        type="hidden"
        name="certification_bodies_name"
        value={get(selected, 'certification_bodies_name', '')}
      />

      <CBSelect
        value={selected || ''}
        onChange={handleChange}
        getOptionLabel={(option) => option['certification_bodies_name']}
        getOptionValue={(option) => ({
          certification_bodies_id: option.certification_bodies_id,
          certification_bodies_name: option.certification_bodies_name,
        })}
        {...rest}
      />
    </>
  );
}

export default ObjectCBSelect;
