import {getAuthSession} from 'api/auth';
import useFetch from 'hooks/useFetch';
import useAlert from 'hooks/useAlert';

function useSessionAccount(initialLoading) {
  const [fetch, isLoading, response] = useFetch(getAuthSession, {
    initialLoading,
  });
  const {alertByError} = useAlert();

  async function getData(session) {
    try {
      await fetch(session);
    } catch (e) {
      await alertByError(e);
    }
  }

  const {data = {}} = response;

  return {
    isLoading,
    data,
    getSessionAccount: getData,
  };
}

export default useSessionAccount;
